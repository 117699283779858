import React from 'react';
import { Link } from 'react-router-dom';
import logoRwNavBar from '../images/escudoMuni.png';
import {Navbar, Nav, NavDropdown, Button} from 'react-bootstrap'
import { FaFacebookSquare,FaTwitter } from "react-icons/fa";
import {db} from '../FirebaseConfig';
import './styles/Navbar.css';

class NavbarRw extends React.Component {
  constructor(){
    super();
    this.state={
      items:[],
    }
    
    this.getCollectionFr();
  }  
  listItemsHome={};

  getCollectionFr(){
    db.collection('home').get()//textos desde firestore
    .then((snapShots)=>{
      this.setState({
        items:snapShots.docs.map(doc => {
          this.state.items = doc.data();
          this.listItemsHome = this.state.items;
        })
      })
    }) 
  }

  render() {
    return (
 
        <Navbar className="rawsonNavBAr" bg="light" expand="lg">
      
          <Navbar.Brand href="#home">
            <img className="img-responsive" 
              src={logoRwNavBar} 
              alt="Logo"
              width="40rem" 
            />
          </Navbar.Brand>
          
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
      
              <Nav.Link><Link to="/" className="linkNoneDec">Turnos Online Municipalidad de Rawson Chubut</Link></Nav.Link>
              
            </Nav>
          <Navbar.Brand href="">
             
            </Navbar.Brand>
   
          </Navbar.Collapse>

        </Navbar>
        
    );
  }
}

export default NavbarRw;
