import React from 'react';
import {Card, Button, Form, Modal } from 'react-bootstrap';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './styles/Home.css';
import axios from 'axios';
import Cookies from 'universal-cookie';
import API from '../components/api';
import api from '../Utils/utilsApi';
var moment = require('moment');

const cookies = new Cookies();
const ESPERA = 10; //Tolerancia en la espera de confirmacion del turno

class Badges extends React.Component {
  constructor(props){
    super(props);
    this.state={
      dia:String(moment().format('LL')),
      esperaEnvio:false,
      diaInicial:null,
      nya:'',
      dni:0,
      email:'',
      preTurno:false,
      confirmacion:false,
      ctrlTurnosCreados:false,
      show:false,
      turno1:false,
      turno2:false,
      turno3:false,
      turno4:false,
      turno5:false,
      selTurno1:false,
      selTurno2:false,
      selTurno3:false,
      selTurno4:false,
      selTurno5:false,
      dni1:'',
      dni2:'',
      dni3:'',
      dni4:'',
      dni5:'',
      nombreApellido1:'',
      nombreApellido2:'',
      nombreApellido3:'',
      nombreApellido4:'',
      nombreApellido5:'',
      mail1:'',
      mail2:'',
      mail3:'',
      mail4:'',
      mail5:''

    } 
    this.handleDatosUsuarios = this.handleDatosUsuarios.bind(this)
  }  
  
  async componentDidMount(){
    var diaInicialAux = new Date();
    var anio=diaInicialAux.getFullYear();
    var mes=diaInicialAux.getMonth();
    var dia=diaInicialAux.getDate()+1;

    var diaInicial = new Date(anio, mes, dia)
    const token =  await API.accessApi();
    cookies.set('token',token);

    this.setState({diaInicial:diaInicial});
  }

  

  async getTurnosDisponible(dia){

    this.setState({
      turno1:false,
      turno2:false,
      turno3:false,
      turno4:false,
      turno5:false,
      selTurno1:false,
      selTurno2:false,
      selTurno3:false,
      selTurno4:false,
      selTurno5:false,
      dni1:'',
      dni2:'',
      dni3:'',
      dni4:'',
      dni5:'',
      nombreApellido1:'',
      nombreApellido2:'',
      nombreApellido3:'',
      nombreApellido4:'',
      nombreApellido5:'',
      mail1:'',
      mail2:'',
      mail3:'',
      mail4:'',
      mail5:''
    })

    var turnosDisponibles = await axios.get(api.api+"/db/munirw/turnosDisonible/findForDate/"+dia,{
      headers:{
        authorization: cookies.get('token'),
      }
    });

    //----------------------------------------------------------------------------------
    if(turnosDisponibles.data.length>0){

    var turnos = await axios.get(api.api+"/db/munirw/turno",{
      headers:{
        authorization: cookies.get('token'),
      }
    });

    var confirmacion1=false;
    var confirmacion2=false;
    var confirmacion3=false;
    var confirmacion4=false;
    var confirmacion5=false;

    turnos.data.map(function(turno) {
      
      if(turno.fechaTurno==dia){
      
        if(turno.dni==turnosDisponibles.data[0].dni1){      
          confirmacion1=turno.confirmacion;

          if((!confirmacion1)){//Si la confirmacion no se hizo me fijo si ya pasaron 10 minutos para dejar el turno libre
          
            confirmacion1=true;//esto significa que todavia no confirmo pero esperamos 10 minutos para que confirme  

            var horaActual = moment().format('HH:mm:ss');
            var horaEnQueReservo = turno.horaEnQueReservo;
            var horaEnQueReservoObj = moment(horaEnQueReservo,'HH:mm:ss');
            var horaActualObj = moment(horaActual,'HH:mm:ss');
            var diferencia = horaActualObj.diff(horaEnQueReservoObj, 'minutes');
            
            if(diferencia > ESPERA){
              confirmacion1=false
            }
          }
        }

        if(turno.dni==turnosDisponibles.data[0].dni2){
          confirmacion2=turno.confirmacion;

          if((!confirmacion2)){//se la confirmacion no se hizo me fijo si ya pasaron 15 minutos para dejar el turno libre
          
            confirmacion2=true;//esto significa que todavia no confirmo pero esperamos 15 minutos para que confirme  

            var horaActual = moment().format('HH:mm:ss');
            var horaEnQueReservo = turno.horaEnQueReservo;
            var horaEnQueReservoObj = moment(horaEnQueReservo,'HH:mm:ss');
            var horaActualObj = moment(horaActual,'HH:mm:ss');
            var diferencia = horaActualObj.diff(horaEnQueReservoObj, 'minutes');
            
            if(diferencia > ESPERA){
              confirmacion2=false
            }
          }

        }

        if(turno.dni==turnosDisponibles.data[0].dni3){
          confirmacion3=turno.confirmacion;

          if((!confirmacion3)){//se la confirmacion no se hizo me fijo si ya pasaron 15 minutos para dejar el turno libre
          
            confirmacion3=true;//esto significa que todavia no confirmo pero esperamos 15 minutos para que confirme  

            var horaActual = moment().format('HH:mm:ss');
            var horaEnQueReservo = turno.horaEnQueReservo;
            var horaEnQueReservoObj = moment(horaEnQueReservo,'HH:mm:ss');
            var horaActualObj = moment(horaActual,'HH:mm:ss');
            var diferencia = horaActualObj.diff(horaEnQueReservoObj, 'minutes');
            
            if(diferencia > ESPERA){
              confirmacion3=false
            }
          }

        }
        if(turno.dni==turnosDisponibles.data[0].dni4){
          confirmacion4=turno.confirmacion;

          if((!confirmacion4)){//si la confirmacion no se hizo me fijo si ya pasaron 15 minutos para dejar el turno libre
          
            confirmacion4=true;//esto significa que todavia no confirmo pero esperamos 15 minutos para que confirme  

            var horaActual = moment().format('HH:mm:ss');
            var horaEnQueReservo = turno.horaEnQueReservo;
            var horaEnQueReservoObj = moment(horaEnQueReservo,'HH:mm:ss');
            var horaActualObj = moment(horaActual,'HH:mm:ss');
            var diferencia = horaActualObj.diff(horaEnQueReservoObj, 'minutes');
            
            if(diferencia > ESPERA){
              confirmacion4=false
            }
          }

        }
        if(turno.dni==turnosDisponibles.data[0].dni5){
          confirmacion5=turno.confirmacion;

          if((!confirmacion5)){//si la confirmacion no se hizo me fijo si ya pasaron 10 minutos para dejar el turno libre
          
            confirmacion5=true;//esto significa que todavia no confirmo pero esperamos 10 minutos para que confirme  

            var horaActual = moment().format('HH:mm:ss');
            var horaEnQueReservo = turno.horaEnQueReservo;
            var horaEnQueReservoObj = moment(horaEnQueReservo,'HH:mm:ss');
            var horaActualObj = moment(horaActual,'HH:mm:ss');
            var diferencia = horaActualObj.diff(horaEnQueReservoObj, 'minutes');
            
            if(diferencia > ESPERA){
              confirmacion5=false
            }
          }
        }
      }       
    });
    //----------------------------------------------------------------------------------

      var turnosDisponiblesAux = turnosDisponibles.data[0];

      this.setState({
        turno1:(turnosDisponiblesAux.Turno1 && confirmacion1),
        turno2:(turnosDisponiblesAux.Turno2 && confirmacion2),
        turno3:(turnosDisponiblesAux.Turno3 && confirmacion3),
        turno4:(turnosDisponiblesAux.Turno4 && confirmacion4),
        turno5:(turnosDisponiblesAux.Turno5 && confirmacion5),
        dni1:turnosDisponiblesAux.dni1,
        dni2:turnosDisponiblesAux.dni2,
        dni3:turnosDisponiblesAux.dni3,
        dni4:turnosDisponiblesAux.dni4,
        dni5:turnosDisponiblesAux.dni5,
        nombreApellido1:turnosDisponiblesAux.nombreApellido1,
        nombreApellido2:turnosDisponiblesAux.nombreApellido2,
        nombreApellido3:turnosDisponiblesAux.nombreApellido3,
        nombreApellido4:turnosDisponiblesAux.nombreApellido4,
        nombreApellido5:turnosDisponiblesAux.nombreApellido5,
        mail1:turnosDisponiblesAux.mail1,
        mail2:turnosDisponiblesAux.mail2,
        mail3:turnosDisponiblesAux.mail3,
        mail4:turnosDisponiblesAux.mail4,
        mail5:turnosDisponiblesAux.mail5,
      })
      
      this.setState({
        show:true,
      })

    }else{
      await axios.post(api.api+"/db/munirw/turnosDisonible",{
          Turno1:this.state.turno1, 
          Turno2:this.state.turno2, 
          Turno3:this.state.turno3, 
          Turno4:this.state.turno4, 
          Turno5:this.state.turno5,    
          dni1:this.state.dni1,
          dni2:this.state.dni2,
          dni3:this.state.dni3,
          dni4:this.state.dni4,
          dni5:this.state.dni5,
          nombreApellido1:this.state.nombreApellido1,
          nombreApellido2:this.state.nombreApellido2,
          nombreApellido3:this.state.nombreApellido3,
          nombreApellido4:this.state.nombreApellido4,
          nombreApellido5:this.state.nombreApellido5,
          mail1:this.state.mail1,
          mail2:this.state.mail2,
          mail3:this.state.mail3,
          mail4:this.state.mail4,
          mail5:this.state.mail5,
          fechaTurno:this.state.dia,
      },
      {
        headers:{
          authorization: cookies.get('token')
        }
      }).then(response => {
        this.setState({
          show:true
        })
      }).catch(e => {
        console.log(e);
      })

    }
  }

  onChangeDia=async dia =>{

    console.log(dia);

    if( (String(dia).indexOf('Sat')>=0)||(String(dia).indexOf('Sun')>=0) ){
      alert("No se puede sacar turnos para Sabados o Domingos");
    }else if(String(dia).indexOf('Apr 02')>=0){
      alert("2 de abril: Día del Veterano y de los Caídos en la guerra de Malvinas");   
    }else{

    this.setState({  
      dia: String(moment(dia).format('LL')),
      preTurno: true,
      turno1:false,
      turno2:false,
      turno3:false,
      turno4:false,
      turno5:false,
      selTurno1:false,
      selTurno2:false,
      selTurno3:false,
      selTurno4:false,
      selTurno5:false,
      dni1:'',
      dni2:'',
      dni3:'',
      dni4:'',
      dni5:'',
      nombreApellido1:'',
      nombreApellido2:'',
      nombreApellido3:'',
      nombreApellido4:'',
      nombreApellido5:'',
      mail1:'',
      mail2:'',
      mail3:'',
      mail4:'',
      mail5:''
    })
    
    var diaFormat = String(moment(dia).format('LL'))
    var turnosDisponibles = await axios.get(api.api+"/db/munirw/turnosDisonible/findForDate/"+diaFormat,{
      headers:{
        authorization: cookies.get('token'),
      }
    });

    if(turnosDisponibles.data.length>0){ 
      var turnosDisponiblesAux = turnosDisponibles.data[0];
      this.setState({
        turno1:turnosDisponiblesAux.Turno1,
        turno2:turnosDisponiblesAux.Turno2,
        turno3:turnosDisponiblesAux.Turno3,
        turno4:turnosDisponiblesAux.Turno4,
        turno5:turnosDisponiblesAux.Turno5,
        dni1:turnosDisponiblesAux.dni1,
        dni2:turnosDisponiblesAux.dni2,
        dni3:turnosDisponiblesAux.dni3,
        dni4:turnosDisponiblesAux.dni4,
        dni5:turnosDisponiblesAux.dni5,
        nombreApellido1:turnosDisponiblesAux.nombreApellido1,
        nombreApellido2:turnosDisponiblesAux.nombreApellido2,
        nombreApellido3:turnosDisponiblesAux.nombreApellido3,
        nombreApellido4:turnosDisponiblesAux.nombreApellido4,
        nombreApellido5:turnosDisponiblesAux.nombreApellido5,
        mail1:turnosDisponiblesAux.mail1,
        mail2:turnosDisponiblesAux.mail2,
        mail3:turnosDisponiblesAux.mail3,
        mail4:turnosDisponiblesAux.mail4,
        mail5:turnosDisponiblesAux.mail5,
        //show: true,
      })
    }
    this.getTurnosDisponible(String(moment(dia).format('LL')));

    }
  }
  
  handleDatosUsuarios(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  }
  
  handleClose=e=>{
    this.setState({show:false})
  }

  hora=event=>{
    if(event.target.value=="turno1"){
      this.setState({
        selTurno1:true,
        selTurno2:false,
        selTurno3:false,
        selTurno4:false,
        selTurno5:false
      })
    }
    if(event.target.value=="turno2"){
      this.setState({
        selTurno1:false,
        selTurno2:true,
        selTurno3:false,
        selTurno4:false,
        selTurno5:false
      })
    }
    if(event.target.value=="turno3"){
      this.setState({
        selTurno1:false,
        selTurno2:false,
        selTurno3:true,
        selTurno4:false,
        selTurno5:false
      })
    }
    if(event.target.value=="turno4"){
      this.setState({
        selTurno1:false,
        selTurno2:false,
        selTurno3:false,
        selTurno4:true,
        selTurno5:false
      })
    }
    if(event.target.value=="turno5"){
      this.setState({
        selTurno1:false,
        selTurno2:false,
        selTurno3:false,
        selTurno4:false,
        selTurno5:true
      })
    }
  }

  reservar=async e=>{

    if(!this.state.selTurno1 && !this.state.selTurno2 && !this.state.selTurno3 && !this.state.selTurno4 && !this.state.selTurno5){
      alert("Se debe elegir una fecha y hora para el Turno)");  
    }else if((this.state.nya=='')||(this.state.dni=='')||(this.state.email=='')){
      alert("Primero completá tus datos (Nombre, Apellido, dni y email)");
    }else{
      //---controlar que la persona no saque dos turnos el mismo dia----
      var turnosDisponibles = await axios.get(api.api+"/db/munirw/turnosDisonible/findForDate/"+this.state.dia,{
        headers:{
          authorization: cookies.get('token'),
        }
      });
      var turnosDisponiblesAux = turnosDisponibles.data[0];
      //================================================================================
      //Si no alcanzo a confirmar el turno, debo dejar que haga mas intentos en el dia para sacar turno a pesar de que se
      //trate del mismo dni o mail. 
      var dniConConfirmacion = false;
      var mailConConfirmacion = false;

      var ChekSidniYaUsadoHoy = await axios.get(api.api+"/db/munirw/turno/porDni/"+this.state.dni+"/"+this.state.dia,{
        headers:{
          authorization: cookies.get('token'),
        }
      });

      var ChekSimailYaUsadoHoy = await axios.get(api.api+"/db/munirw/turno/porMail/"+this.state.email+"/"+this.state.dia,{
        headers:{
          authorization: cookies.get('token'),
        }
      });

     
      //si por dni esta confirmado no debo dejar que reserve con este dni nuevamente
      if(ChekSidniYaUsadoHoy.data.confirmacion!=undefined){
        dniConConfirmacion=ChekSidniYaUsadoHoy.data.confirmacion
      }
      if(ChekSimailYaUsadoHoy.data.confirmacion!=undefined){
        mailConConfirmacion=ChekSimailYaUsadoHoy.data.confirmacion
      }
      

      var presenciasDni = 1; //para controlar si un usuario quiere sacar mas de un turno en un dia
      var presenciasEmail = 1;

      for (const property in turnosDisponiblesAux) {
        if(turnosDisponiblesAux[property]==this.state.dni){
          presenciasDni++;
        }      
        if(turnosDisponiblesAux[property]==this.state.email){
          presenciasEmail++;
        }      
      }

      if( ((presenciasDni>1)||(presenciasEmail>1))&&(dniConConfirmacion||mailConConfirmacion) ){  
        alert("Ya hay un turno registrado para el dia de hoy con el dni o email ingresado, no puede solicitar dos turnos el mismo dia.");   
      }else{
      //----------------------------------------------------------------

        //if(!this.state.selTurno1 && !this.state.selTurno2 && !this.state.selTurno3 && !this.state.selTurno4 && !this.state.selTurno5){
        //    alert("Se debe elegir una fecha y hora para el Turno)");  
        //}else if((this.state.nya=='')||(this.state.dni=='')||(this.state.email=='')){
        //    alert("Primero completá tus datos (Nombre, Apellido, dni y email)");
        //}else{

          if(this.state.selTurno1){
            this.setState({
              turno1:true,
              dni1:this.state.dni,
              nombreApellido1:this.state.nya,
              mail1:this.state.email,
              esperaEnvio:true
            });
          }
          if(this.state.selTurno2){
            this.setState({
              turno2:true,
              dni2:this.state.dni,
              nombreApellido2:this.state.nya,
              mail2:this.state.email,
              esperaEnvio:true
            });
          }
          if(this.state.selTurno3){
            this.setState({
              turno3:true,
              dni3:this.state.dni,
              nombreApellido3:this.state.nya,
              mail3:this.state.email,
              esperaEnvio:true
            });
          }
          if(this.state.selTurno4){ 
            this.setState({
              turno4:true,
              dni4:this.state.dni,
              nombreApellido4:this.state.nya,
              mail4:this.state.email,
              esperaEnvio:true
            });
          }
          if(this.state.selTurno5){
            this.setState({
              turno5:true,
              dni5:this.state.dni,
              nombreApellido5:this.state.nya,
              mail5:this.state.email,
              esperaEnvio:true
            });
          }   

          await axios.post(api.api+"/db/munirw/turno",{
              fechaTurno:this.state.dia,
              dni:this.state.dni,
              email:this.state.email,
              nombreApellido:this.state.nya,
              preTurno:this.state.preTurno,
              confirmacion:this.state.confirmacion,
              horaEnQueReservo:moment().format('HH:mm:ss'),
          },
          {
            headers:{
              authorization: cookies.get('token')
            }
          })

          var dia=this.state.dia;
          await axios.put(api.api+"/db/munirw/turnosDisonible/findForDate/"+dia,{
              Turno1:this.state.turno1, 
              Turno2:this.state.turno2, 
              Turno3:this.state.turno3, 
              Turno4:this.state.turno4, 
              Turno5:this.state.turno5,    
              dni1:this.state.dni1,
              dni2:this.state.dni2,
              dni3:this.state.dni3,
              dni4:this.state.dni4,
              dni5:this.state.dni5,
              nombreApellido1:this.state.nombreApellido1,
              nombreApellido2:this.state.nombreApellido2,
              nombreApellido3:this.state.nombreApellido3,
              nombreApellido4:this.state.nombreApellido4,
              nombreApellido5:this.state.nombreApellido5,
              mail1:this.state.mail1,
              mail2:this.state.mail2,
              mail3:this.state.mail3,
              mail4:this.state.mail4,
              mail5:this.state.mail5,
              fechaTurno:this.state.dia
          },
          {
            headers:{
              authorization: cookies.get('token')
            }
          })
          
          await axios.post(api.api+"/db/munirw/msj",{
              fechaTurno:this.state.dia,
              dni:this.state.dni,
              email:this.state.email,
              nombreApellido:this.state.nya,
          },
          {
            headers:{
              authorization: cookies.get('token')
            }
          }).then(response => {
            this.setState({
              dia:String(moment().format('LL')),
              esperaEnvio:false,
              nya:'',
              dni:0,
              email:'',
              preTurno:false,
              confirmacion:false,
              ctrlTurnosCreados:false,
              show:false,
              turno1:false,
              turno2:false,
              turno3:false,
              turno4:false,
              turno5:false,
              selTurno1:false,
              selTurno2:false,
              selTurno3:false,
              selTurno4:false,
              selTurno5:false,
              dni1:'',
              dni2:'',
              dni3:'',
              dni4:'',
              dni5:'',
              nombreApellido1:'',
              nombreApellido2:'',
              nombreApellido3:'',
              nombreApellido4:'',
              nombreApellido5:'',
              mail1:'',
              mail2:'',
              mail3:'',
              mail4:'',
              mail5:''
            })
            alert("Confirmá tu turno entrando al mail que usaste tenés 10 minutos para hacerlo");
          }).catch(e => {
            console.log(e);
          })     
        //--}
      }
    }  
  }

  render() {
    if(this.state.esperaEnvio){
      return (
        <React.Fragment>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-4"></div>
              <div className="col-md-4">
                <Card>
                    <Card.Img variant="top" src={require('../images/turnos/Loading.gif')} />
                    <Card.Body>
                      <Card.Title>Enviando Confirmación a su Mail</Card.Title>
                      <Card.Text>

                      </Card.Text>
                    </Card.Body>
                </Card>  
              </div>
              <div className="col-md-4"></div>
            </div> 
          </div>
        </React.Fragment> 
      )
    }else{
      return (

        <React.Fragment> 

          <div className="container-fluid pt-5 pb-5">
      
            <div className="row">
              <div className="col-md-1"></div>
              <div className="col-md-4">

              <Card>
                <Card.Img variant="top" src={require('../images/turnos/banner.jpg')} />
                <Card.Body>
                  <Card.Title>Ingresá tus datos para solicitar turno</Card.Title>
                  <Card.Text>

                  <Form>

                  <Form.Group controlId="nya">
                    <Form.Label>Nombre y Apellido</Form.Label>
                    <Form.Control autocomplete="off" type="text" name="nya" placeholder="Nombre y Apellido" onChange={this.handleDatosUsuarios} value={this.state.nya}/>
                  </Form.Group>

                  <Form.Group controlId="dni">
                    <Form.Label>DNI</Form.Label>
                    <Form.Control autocomplete="off" type="text" name="dni" placeholder="dni" onChange={this.handleDatosUsuarios} value={this.state.dni}/>
                  </Form.Group>

                  <Form.Group controlId="email">
                    <Form.Label>Email (se recomienda gmail)</Form.Label>
                    <Form.Control autocomplete="off" type="email" name="email" placeholder="Email" onChange={this.handleDatosUsuarios} value={this.state.email}/>
                  </Form.Group>

                  </Form>
                    
                  </Card.Text>
                </Card.Body>
              </Card>  
              
              
        
              <div className="col-md-1"></div>

              </div>

              <div className="col-md-1"></div>

              <div className="col-md-4">
              
              <Card>
              
                <Card.Body>
                  <Card.Title>Elegí el dia y hora disponibles</Card.Title>
                  <Card.Text>
        
                    <Calendar  
                      onChange={this.onChangeDia}
                      value={this.state.date}
                      minDate={this.state.diaInicial}
                      maxDate={new Date(2022, 5, 30)} 
                    />  

                    <Form.Group className="pt-5" controlId="guardar">
                      <Button  variant="primary" type="submit" onClick={this.reservar}>
                        RESERVAR
                      </Button>
                    </Form.Group>

                  </Card.Text>
                </Card.Body>
              </Card>  
              </div>

              <div className="col-md-1"></div>
            </div>

          </div>

          <div className="container-fluid tonina">
            <div className="col-lg-12"> 
              <div className="row rowAltura">

              <Modal show={this.state.show} onHide={this.handleClose}>
                
                <Modal.Header closeButton>
                  <Modal.Title>Elegí una Hora Disponible</Modal.Title>
                </Modal.Header>
                
                <Modal.Body>

                  <Form.Group controlId="formBasicCheckbox">

                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value="turno1" disabled={this.state.turno1} onClick={this.hora}/> {/*checked checked={this.state.chek}*/}
                    <h5 class="form-check-label" for="flexRadioDefault1">
                      8:30
                    </h5>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value="turno2" disabled={this.state.turno2} onClick={this.hora}/>
                    <h5 class="form-check-label" for="flexRadioDefault2">
                      9:15
                    </h5>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" value="turno3" disabled={this.state.turno3} onClick={this.hora}/>
                    <h5 class="form-check-label" for="flexRadioDefault1">
                      10:00
                    </h5>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault4" value="turno4" disabled={this.state.turno4} onClick={this.hora}/>
                    <h5 class="form-check-label" for="flexRadioDefault2">
                      10:45
                    </h5>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault5" value="turno5" disabled={this.state.turno5} onClick={this.hora}/>
                    <h5 class="form-check-label" for="flexRadioDefault2">
                      11:30
                    </h5>
                  </div>

              
                  </Form.Group>
                  
                </Modal.Body>
                
                <Modal.Footer>
                  <Button variant="primary" onClick={this.handleClose}>
                    ACEPTAR
                  </Button>
                  </Modal.Footer> 
                </Modal>

              </div>  
            </div>
          </div>
        </React.Fragment>
      
      );
    }
  }
}

export default Badges;
