import React from 'react';
import api from '../Utils/utilsApi';
import axios from 'axios';
import { StyleSheet } from '@react-pdf/renderer';
import './styles/Home.css'

var moment = require('moment');

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  }
});

class Confirmacion extends React.Component {
  
  constructor(props){
    super(props);
    this.state={
      confirmado:false,
      fechaTurno:'',
      dni:'',
      email:'',
      nombreApellido:'',
      horaDelTurno:'',
      msj:''
    }
  }  


  async componentDidMount(){

    const { match: { params } } = this.props;
    var confirmadoAux = false;
    var fechaTurnoAux,fechaTurnoAuxAesp,dniAux,emailAux,nombreApellidoAux,horaDelTurnoAux,msjAux,confirmadoMsjAux;

    await axios.post(api.api+"/db/munirw/confirmacion",{
          dni:params.dni,
          fechaTurno:params.fechaTurno,
          horaActual:moment().format('HH:mm:ss'),

    }).then(function (response) {

      if(response.data.estado=='confirmado'){

        confirmadoMsjAux='confirmado';

        if(response.data.fechaTurno.indexOf('January')>=0){
          fechaTurnoAuxAesp=response.data.fechaTurno.replace('January','Enero');
        }
        if(response.data.fechaTurno.indexOf('February')>=0){
          fechaTurnoAuxAesp=response.data.fechaTurno.replace('February','Febrero');
        }
        if(response.data.fechaTurno.indexOf('March')>=0){
          fechaTurnoAuxAesp=response.data.fechaTurno.replace('March','Marzo');
        }
        if(response.data.fechaTurno.indexOf('April')>=0){
          fechaTurnoAuxAesp=response.data.fechaTurno.replace('April','Abril');
        }
        if(response.data.fechaTurno.indexOf('May')>=0){
          fechaTurnoAuxAesp=response.data.fechaTurno.replace('May','Mayo');
        }
        if(response.data.fechaTurno.indexOf('June')>=0){
          fechaTurnoAuxAesp=response.data.fechaTurno.replace('June','Junio');
        }
        if(response.data.fechaTurno.indexOf('July')>=0){
          fechaTurnoAuxAesp=response.data.fechaTurno.replace('July','Julio');
        }
        if(response.data.fechaTurno.indexOf('August')>=0){
          fechaTurnoAuxAesp=response.data.fechaTurno.replace('August','Agosto');
        }
        if(response.data.fechaTurno.indexOf('September')>=0){
          fechaTurnoAuxAesp=response.data.fechaTurno.replace('September','Septiembre');
        }
        if(response.data.fechaTurno.indexOf('October')>=0){
          fechaTurnoAuxAesp=response.data.fechaTurno.replace('October','Octubre');
        }
        if(response.data.fechaTurno.indexOf('November')>=0){
          fechaTurnoAuxAesp=response.data.fechaTurno.replace('November','Noviembre');
        }
        if(response.data.fechaTurno.indexOf('December')>=0){
          fechaTurnoAuxAesp=response.data.fechaTurno.replace('December','Diciembre');
        }  

        confirmadoAux =true;
        fechaTurnoAux = fechaTurnoAuxAesp;
        dniAux = response.data.dni;
        emailAux = response.data.email;
        nombreApellidoAux = response.data.nombreApellido; 
        horaDelTurnoAux = response.data.horaDelTurno   
      }
      if(response.data.estado=='caduco'){
        confirmadoMsjAux='caduco';
        msjAux=response.data.msj
      }
    })

    if(confirmadoMsjAux=='confirmado'){
      this.setState({
        confirmado:confirmadoAux,
        fechaTurno:fechaTurnoAux,
        horaDelTurno:horaDelTurnoAux,
        dni:dniAux,
        email:emailAux,
        nombreApellido:nombreApellidoAux
      })
    }

    if(confirmadoMsjAux=='caduco'){
      this.setState({
        msj:msjAux
      })
    }
  }

  
  render() {
    if(this.state.confirmado){
      return (
        <React.Fragment> 
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-1"></div>
              <div className="col-md-10">
                
                <div class="card">
                  <div class="card-body">
                    <h2 class="card-title">Turno Confirmado</h2>
                    <h3 class="card-subtitle mb-2 text-muted">Municipalidad de Rawson Chubut</h3>
                    <h4 class="card-text">Fecha del Turno: {this.state.fechaTurno}</h4>
                    <h4 class="card-text">Hora del Turno: {this.state.horaDelTurno}hs</h4>
                    <h4 class="card-text">Nombre y Apellido: {this.state.nombreApellido}</h4>
                    <h4 class="card-text">Dni: {this.state.dni}</h4>
                  
                  </div>
                </div>

              </div>
              <div className="col-md-1"></div>
            </div>
          </div>

     
        </React.Fragment>
      );
    }else{
      return (
        <React.Fragment> 
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-1"></div>
              <div className="col-md-10">
                
                <div class="card">
                  <div class="card-body">
                    <h2 class="card-title">No se puede realizar la confirmación.</h2>
                    <h3 class="card-subtitle mb-2 text-muted">Municipalidad de Rawson Chubut</h3> 
                    <h4 class="card-text">Motivo: {this.state.msj}</h4>
                  </div>
                </div>

              </div>
              <div className="col-md-1"></div>
            </div>
          </div>
       
        </React.Fragment>
      );
    }
  }
}

export default Confirmacion;
