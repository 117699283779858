import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import './styles/Badge.css'
import Layout from './Layout';
import Home from '../pages/Home';
import HomeLotear from '../pages/HomeLotear';
import HomeLotearMesa2 from '../pages/HomeLotearMesa2';

import Confirmacion from '../pages/Confirmacion';
import ConfirmacionLotear from '../pages/ConfirmacionLotear';
import ConfirmacionLotearMesa2 from '../pages/ConfirmacionLotearMesa2';

import Exhibicion from '../pages/Exhibicion';
import ExhibicionLotear from '../pages/ExhibicionLotear'
import ExhibicionLotearMesa2 from '../pages/ExhibicionLotearMesa2'


import NotFound from '../pages/NotFound';

{/*------ROUTER-----*/ }
function App() {
  return (
    <BrowserRouter>
      <Layout>
        <Switch>

          <Route exact path="/" component={Home} />
          <Route exact path="/confirmacion/:dni/:fechaTurno" component={Confirmacion} />
          <Route exact path="/exhibicion/:dni/:fechaTurno" component={Exhibicion} />

          <Route exact path="/lotear/mesa1" component={HomeLotear} />
          <Route exact path="/confirmacion/lotear/mesa1/:dni/:fechaTurno" component={ConfirmacionLotear} />
          <Route exact path="/exhibicion/lotear/mesa1/:dni/:fechaTurno" component={ExhibicionLotear} />

          <Route exact path="/lotear/mesa2" component={HomeLotearMesa2} />
          <Route exact path="/confirmacion/lotear/mesa2/:dni/:fechaTurno" component={ConfirmacionLotearMesa2} />
          <Route exact path="/exhibicion/lotear/mesa2/:dni/:fechaTurno" component={ExhibicionLotearMesa2} />

          <Route component={NotFound} />

        </Switch>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
