import axios from 'axios';
class Api{
    async accessApi(){
        var mail = process.env.REACT_APP_FIREBASE_KEY;
        var pass = process.env.REACT_APP_FIREBASE_DOMAIN;
        return await axios.post("https://apiadmin.rawson.gov.ar/db/munirw/mihospedaje/auth",{
        
            email: mail,
            pass: pass
    
        }).then(response => {
            return response.data.token
        }).catch(e => {
            console.log('FATAL ERROR :(',e);
            return('error al obtener autorización')
        })
    }
}

export default new Api();
