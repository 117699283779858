import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage'

firebase.initializeApp({
    
    apiKey: process.env.REACT_APP_FIREBASE_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE,
    messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER,
    appId: process.env.REACT_APP_FIREBASE_APP,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT
});

let db = firebase.firestore();
let st = firebase.storage();

//db.settings({timestampsInSnapshots:true});


export{db,st}
