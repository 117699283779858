import React from 'react';
import { Link } from "react-router-dom";
import {Card, Button, Form, Modal } from 'react-bootstrap';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './styles/Home.css';
import axios from 'axios';
import Cookies from 'universal-cookie';
import API from '../components/api';
import api from '../Utils/utilsApi';
var moment = require('moment');

const cookies = new Cookies();
const ESPERA = 10; //Tolerancia en la espera de confirmacion del turno

class Badges extends React.Component {
  constructor(props){
    super(props);
    this.state={
      dia:String(moment().format('LL')),
      esperaEnvio:false,
      diaInicial:null,
      nya:'',
      dni:0,
      email:'',
      preTurno:false,
      confirmacion:false,
      ctrlTurnosCreados:false,
      show:false,
      loadHorarios:'not',

      turno1:false,
      turno2:false,
      turno3:false,
      turno4:false,
      turno5:false,

      turno6:false,
      turno7:false,
      turno8:false,
      turno9:false,
      turno10:false,
      
      turno11:false,
      turno12:false,
      turno13:false,
      turno14:false,
      turno15:false,

      turno16:false,
      turno17:false,
      turno18:false,
      turno19:false,

      selTurno1:false,
      selTurno2:false,
      selTurno3:false,
      selTurno4:false,
      selTurno5:false,

      selTurno6:false,
      selTurno7:false,
      selTurno8:false,
      selTurno9:false,
      selTurno10:false,

      selTurno11:false,
      selTurno12:false,
      selTurno13:false,
      selTurno14:false,
      selTurno15:false,

      selTurno16:false,
      selTurno17:false,
      selTurno18:false,
      selTurno19:false,

      dni1:'',
      dni2:'',
      dni3:'',
      dni4:'',
      dni5:'',

      dni6:'',
      dni7:'',
      dni8:'',
      dni9:'',
      dni10:'',

      dni11:'',
      dni12:'',
      dni13:'',
      dni14:'',
      dni15:'',

      dni16:'',
      dni17:'',
      dni18:'',
      dni19:'',

      nombreApellido1:'',
      nombreApellido2:'',
      nombreApellido3:'',
      nombreApellido4:'',
      nombreApellido5:'',

      nombreApellido6:'',
      nombreApellido7:'',
      nombreApellido8:'',
      nombreApellido9:'',
      nombreApellido10:'',

      nombreApellido11:'',
      nombreApellido12:'',
      nombreApellido13:'',
      nombreApellido14:'',
      nombreApellido15:'',

      nombreApellido16:'',
      nombreApellido17:'',
      nombreApellido18:'',
      nombreApellido19:'',

      mail1:'',
      mail2:'',
      mail3:'',
      mail4:'',
      mail5:'',

      mail6:'',
      mail7:'',
      mail8:'',
      mail9:'',
      mail10:'',

      mail11:'',
      mail12:'',
      mail13:'',
      mail14:'',
      mail15:'',

      mail16:'',
      mail17:'',
      mail18:'',
      mail19:'',

      nya:cookies.get('nya'),
      dni:cookies.get('dni'),
      email:cookies.get('email')

    } 
    this.handleDatosUsuarios = this.handleDatosUsuarios.bind(this)
  }  
  
  async componentDidMount(){
    var diaInicialAux = new Date();
    var anio=diaInicialAux.getFullYear();
    var mes=diaInicialAux.getMonth();
    var dia=diaInicialAux.getDate()+1;

    var diaInicial = new Date(anio, mes, dia)
    const token =  await API.accessApi();
    cookies.set('token',token);

    this.setState({diaInicial:diaInicial});
  }

  

  async getTurnosDisponible(dia){

    this.setState({
      turno1:false,
      turno2:false,
      turno3:false,
      turno4:false,
      turno5:false,

      turno6:false,
      turno7:false,
      turno8:false,
      turno9:false,
      turno10:false,

      turno11:false,
      turno12:false,
      turno13:false,
      turno14:false,
      turno15:false,

      turno16:false,
      turno17:false,
      turno18:false,
      turno19:false,

      selTurno1:false,
      selTurno2:false,
      selTurno3:false,
      selTurno4:false,
      selTurno5:false,

      selTurno6:false,
      selTurno7:false,
      selTurno8:false,
      selTurno9:false,
      selTurno10:false,

      selTurno11:false,
      selTurno12:false,
      selTurno13:false,
      selTurno14:false,
      selTurno15:false,

      selTurno16:false,
      selTurno17:false,
      selTurno18:false,
      selTurno19:false,

      dni1:'',
      dni2:'',
      dni3:'',
      dni4:'',
      dni5:'',

      dni6:'',
      dni7:'',
      dni8:'',
      dni9:'',
      dni10:'',

      dni11:'',
      dni12:'',
      dni13:'',
      dni14:'',
      dni15:'',

      dni16:'',
      dni17:'',
      dni18:'',
      dni19:'',

      nombreApellido1:'',
      nombreApellido2:'',
      nombreApellido3:'',
      nombreApellido4:'',
      nombreApellido5:'',

      nombreApellido6:'',
      nombreApellido7:'',
      nombreApellido8:'',
      nombreApellido9:'',
      nombreApellido10:'',

      nombreApellido11:'',
      nombreApellido12:'',
      nombreApellido13:'',
      nombreApellido14:'',
      nombreApellido15:'',

      nombreApellido16:'',
      nombreApellido17:'',
      nombreApellido18:'',
      nombreApellido19:'',

      mail1:'',
      mail2:'',
      mail3:'',
      mail4:'',
      mail5:'',

      mail6:'',
      mail7:'',
      mail8:'',
      mail9:'',
      mail10:'',

      mail11:'',
      mail12:'',
      mail13:'',
      mail14:'',
      mail15:'',

      mail16:'',
      mail17:'',
      mail18:'',
      mail19:''

    })

    var turnosDisponibles = await axios.get(api.api+"/db/munirw/lotear/mesa2/turnosDisonible/findForDate/"+dia,{
      headers:{
        authorization: cookies.get('token'),
      }
    });

    //----------------------------------------------------------------------------------
    if(turnosDisponibles.data.length>0){

      //---para controlar si ya no hay lugar en el dia
      var turnosDisponiblesAux = turnosDisponibles.data[0];

      if(
          turnosDisponiblesAux.Turno1&&
          turnosDisponiblesAux.Turno2&&
          turnosDisponiblesAux.Turno3&&
          turnosDisponiblesAux.Turno4&&
          turnosDisponiblesAux.Turno5&&
          turnosDisponiblesAux.Turno6&&
          turnosDisponiblesAux.Turno7&&
          turnosDisponiblesAux.Turno8&&
          turnosDisponiblesAux.Turno9&&
          turnosDisponiblesAux.Turno10&&
          turnosDisponiblesAux.Turno11&&
          turnosDisponiblesAux.Turno12&&
          turnosDisponiblesAux.Turno13&&
          turnosDisponiblesAux.Turno14&&
          turnosDisponiblesAux.Turno15&&
          turnosDisponiblesAux.Turno16&&
          turnosDisponiblesAux.Turno17&&
          turnosDisponiblesAux.Turno18&&
          turnosDisponiblesAux.Turno19
      ){
        alert('Ya no hay lugar para este dia, intente con otra mesa u otra fecha.')
      }else{

        var turnos = await axios.get(api.api+"/db/munirw/lotear/mesa2/turno",{
          headers:{
            authorization: cookies.get('token'),
          }
        });

        var confirmacion1=false;
        var confirmacion2=false;
        var confirmacion3=false;
        var confirmacion4=false;
        var confirmacion5=false;

        var confirmacion6=false;
        var confirmacion7=false;
        var confirmacion8=false;
        var confirmacion9=false;
        var confirmacion10=false;

        var confirmacion11=false;
        var confirmacion12=false;
        var confirmacion13=false;
        var confirmacion14=false;
        var confirmacion15=false;

        var confirmacion16=false;
        var confirmacion17=false;
        var confirmacion18=false;
        var confirmacion19=false;

        turnos.data.map(function(turno) {
          
          if(turno.fechaTurno==dia){
          
            if(turno.dni==turnosDisponibles.data[0].dni1){      
              confirmacion1=turno.confirmacion;

              if((!confirmacion1)){//Si la confirmacion no se hizo me fijo si ya pasaron 10 minutos para dejar el turno libre
              
                confirmacion1=true;//esto significa que todavia no confirmo pero esperamos 10 minutos para que confirme  

                var horaActual = moment().format('HH:mm:ss');
                var horaEnQueReservo = turno.horaEnQueReservo;
                var horaEnQueReservoObj = moment(horaEnQueReservo,'HH:mm:ss');
                var horaActualObj = moment(horaActual,'HH:mm:ss');
                var diferencia = horaActualObj.diff(horaEnQueReservoObj, 'minutes');
                
                if(diferencia > ESPERA){
                  confirmacion1=false
                }
              }
            }

            if(turno.dni==turnosDisponibles.data[0].dni2){
              confirmacion2=turno.confirmacion;

              if((!confirmacion2)){//se la confirmacion no se hizo me fijo si ya pasaron 15 minutos para dejar el turno libre
              
                confirmacion2=true;//esto significa que todavia no confirmo pero esperamos 15 minutos para que confirme  

                var horaActual = moment().format('HH:mm:ss');
                var horaEnQueReservo = turno.horaEnQueReservo;
                var horaEnQueReservoObj = moment(horaEnQueReservo,'HH:mm:ss');
                var horaActualObj = moment(horaActual,'HH:mm:ss');
                var diferencia = horaActualObj.diff(horaEnQueReservoObj, 'minutes');
                
                if(diferencia > ESPERA){
                  confirmacion2=false
                }
              }

            }

            if(turno.dni==turnosDisponibles.data[0].dni3){
              confirmacion3=turno.confirmacion;

              if((!confirmacion3)){//se la confirmacion no se hizo me fijo si ya pasaron 15 minutos para dejar el turno libre
              
                confirmacion3=true;//esto significa que todavia no confirmo pero esperamos 15 minutos para que confirme  

                var horaActual = moment().format('HH:mm:ss');
                var horaEnQueReservo = turno.horaEnQueReservo;
                var horaEnQueReservoObj = moment(horaEnQueReservo,'HH:mm:ss');
                var horaActualObj = moment(horaActual,'HH:mm:ss');
                var diferencia = horaActualObj.diff(horaEnQueReservoObj, 'minutes');
                
                if(diferencia > ESPERA){
                  confirmacion3=false
                }
              }

            }
            if(turno.dni==turnosDisponibles.data[0].dni4){
              confirmacion4=turno.confirmacion;

              if((!confirmacion4)){//si la confirmacion no se hizo me fijo si ya pasaron 15 minutos para dejar el turno libre
              
                confirmacion4=true;//esto significa que todavia no confirmo pero esperamos 15 minutos para que confirme  

                var horaActual = moment().format('HH:mm:ss');
                var horaEnQueReservo = turno.horaEnQueReservo;
                var horaEnQueReservoObj = moment(horaEnQueReservo,'HH:mm:ss');
                var horaActualObj = moment(horaActual,'HH:mm:ss');
                var diferencia = horaActualObj.diff(horaEnQueReservoObj, 'minutes');
                
                if(diferencia > ESPERA){
                  confirmacion4=false
                }
              }

            }

            if(turno.dni==turnosDisponibles.data[0].dni5){
              confirmacion5=turno.confirmacion;

              if((!confirmacion5)){//si la confirmacion no se hizo me fijo si ya pasaron 10 minutos para dejar el turno libre
              
                confirmacion5=true;//esto significa que todavia no confirmo pero esperamos 10 minutos para que confirme  

                var horaActual = moment().format('HH:mm:ss');
                var horaEnQueReservo = turno.horaEnQueReservo;
                var horaEnQueReservoObj = moment(horaEnQueReservo,'HH:mm:ss');
                var horaActualObj = moment(horaActual,'HH:mm:ss');
                var diferencia = horaActualObj.diff(horaEnQueReservoObj, 'minutes');
                
                if(diferencia > ESPERA){
                  confirmacion5=false
                }
              }
            }


            if(turno.dni==turnosDisponibles.data[0].dni6){
              confirmacion6=turno.confirmacion;

              if((!confirmacion6)){//si la confirmacion no se hizo me fijo si ya pasaron 10 minutos para dejar el turno libre
              
                confirmacion6=true;//esto significa que todavia no confirmo pero esperamos 10 minutos para que confirme  

                var horaActual = moment().format('HH:mm:ss');
                var horaEnQueReservo = turno.horaEnQueReservo;
                var horaEnQueReservoObj = moment(horaEnQueReservo,'HH:mm:ss');
                var horaActualObj = moment(horaActual,'HH:mm:ss');
                var diferencia = horaActualObj.diff(horaEnQueReservoObj, 'minutes');
                
                if(diferencia > ESPERA){
                  confirmacion6=false
                }
              }
            }

            if(turno.dni==turnosDisponibles.data[0].dni7){
              confirmacion7=turno.confirmacion;

              if((!confirmacion7)){//si la confirmacion no se hizo me fijo si ya pasaron 10 minutos para dejar el turno libre
              
                confirmacion7=true;//esto significa que todavia no confirmo pero esperamos 10 minutos para que confirme  

                var horaActual = moment().format('HH:mm:ss');
                var horaEnQueReservo = turno.horaEnQueReservo;
                var horaEnQueReservoObj = moment(horaEnQueReservo,'HH:mm:ss');
                var horaActualObj = moment(horaActual,'HH:mm:ss');
                var diferencia = horaActualObj.diff(horaEnQueReservoObj, 'minutes');
                
                if(diferencia > ESPERA){
                  confirmacion7=false
                }
              }
            }

            if(turno.dni==turnosDisponibles.data[0].dni8){
              confirmacion8=turno.confirmacion;

              if((!confirmacion8)){//si la confirmacion no se hizo me fijo si ya pasaron 10 minutos para dejar el turno libre
              
                confirmacion8=true;//esto significa que todavia no confirmo pero esperamos 10 minutos para que confirme  

                var horaActual = moment().format('HH:mm:ss');
                var horaEnQueReservo = turno.horaEnQueReservo;
                var horaEnQueReservoObj = moment(horaEnQueReservo,'HH:mm:ss');
                var horaActualObj = moment(horaActual,'HH:mm:ss');
                var diferencia = horaActualObj.diff(horaEnQueReservoObj, 'minutes');
                
                if(diferencia > ESPERA){
                  confirmacion8=false
                }
              }
            }

            if(turno.dni==turnosDisponibles.data[0].dni9){
              confirmacion9=turno.confirmacion;

              if((!confirmacion9)){//si la confirmacion no se hizo me fijo si ya pasaron 10 minutos para dejar el turno libre
              
                confirmacion9=true;//esto significa que todavia no confirmo pero esperamos 10 minutos para que confirme  

                var horaActual = moment().format('HH:mm:ss');
                var horaEnQueReservo = turno.horaEnQueReservo;
                var horaEnQueReservoObj = moment(horaEnQueReservo,'HH:mm:ss');
                var horaActualObj = moment(horaActual,'HH:mm:ss');
                var diferencia = horaActualObj.diff(horaEnQueReservoObj, 'minutes');
                
                if(diferencia > ESPERA){
                  confirmacion9=false
                }
              }
            }

            if(turno.dni==turnosDisponibles.data[0].dni10){
              confirmacion10=turno.confirmacion;

              if((!confirmacion10)){//si la confirmacion no se hizo me fijo si ya pasaron 10 minutos para dejar el turno libre
              
                confirmacion10=true;//esto significa que todavia no confirmo pero esperamos 10 minutos para que confirme  

                var horaActual = moment().format('HH:mm:ss');
                var horaEnQueReservo = turno.horaEnQueReservo;
                var horaEnQueReservoObj = moment(horaEnQueReservo,'HH:mm:ss');
                var horaActualObj = moment(horaActual,'HH:mm:ss');
                var diferencia = horaActualObj.diff(horaEnQueReservoObj, 'minutes');
                
                if(diferencia > ESPERA){
                  confirmacion10=false
                }
              }
            }

            if(turno.dni==turnosDisponibles.data[0].dni11){
              confirmacion11=turno.confirmacion;

              if((!confirmacion11)){//si la confirmacion no se hizo me fijo si ya pasaron 10 minutos para dejar el turno libre
              
                confirmacion11=true;//esto significa que todavia no confirmo pero esperamos 10 minutos para que confirme  

                var horaActual = moment().format('HH:mm:ss');
                var horaEnQueReservo = turno.horaEnQueReservo;
                var horaEnQueReservoObj = moment(horaEnQueReservo,'HH:mm:ss');
                var horaActualObj = moment(horaActual,'HH:mm:ss');
                var diferencia = horaActualObj.diff(horaEnQueReservoObj, 'minutes');
                
                if(diferencia > ESPERA){
                  confirmacion11=false
                }
              }
            }

            if(turno.dni==turnosDisponibles.data[0].dni12){
              confirmacion12=turno.confirmacion;

              if((!confirmacion12)){//si la confirmacion no se hizo me fijo si ya pasaron 10 minutos para dejar el turno libre
              
                confirmacion12=true;//esto significa que todavia no confirmo pero esperamos 10 minutos para que confirme  

                var horaActual = moment().format('HH:mm:ss');
                var horaEnQueReservo = turno.horaEnQueReservo;
                var horaEnQueReservoObj = moment(horaEnQueReservo,'HH:mm:ss');
                var horaActualObj = moment(horaActual,'HH:mm:ss');
                var diferencia = horaActualObj.diff(horaEnQueReservoObj, 'minutes');
                
                if(diferencia > ESPERA){
                  confirmacion12=false
                }
              }
            }

            if(turno.dni==turnosDisponibles.data[0].dni13){
              confirmacion13=turno.confirmacion;

              if((!confirmacion13)){//si la confirmacion no se hizo me fijo si ya pasaron 10 minutos para dejar el turno libre
              
                confirmacion13=true;//esto significa que todavia no confirmo pero esperamos 10 minutos para que confirme  

                var horaActual = moment().format('HH:mm:ss');
                var horaEnQueReservo = turno.horaEnQueReservo;
                var horaEnQueReservoObj = moment(horaEnQueReservo,'HH:mm:ss');
                var horaActualObj = moment(horaActual,'HH:mm:ss');
                var diferencia = horaActualObj.diff(horaEnQueReservoObj, 'minutes');
                
                if(diferencia > ESPERA){
                  confirmacion13=false
                }
              }
            }

            if(turno.dni==turnosDisponibles.data[0].dni14){
              confirmacion14=turno.confirmacion;

              if((!confirmacion14)){//si la confirmacion no se hizo me fijo si ya pasaron 10 minutos para dejar el turno libre
              
                confirmacion14=true;//esto significa que todavia no confirmo pero esperamos 10 minutos para que confirme  

                var horaActual = moment().format('HH:mm:ss');
                var horaEnQueReservo = turno.horaEnQueReservo;
                var horaEnQueReservoObj = moment(horaEnQueReservo,'HH:mm:ss');
                var horaActualObj = moment(horaActual,'HH:mm:ss');
                var diferencia = horaActualObj.diff(horaEnQueReservoObj, 'minutes');
                
                if(diferencia > ESPERA){
                  confirmacion14=false
                }
              }
            }

            if(turno.dni==turnosDisponibles.data[0].dni15){
              confirmacion15=turno.confirmacion;

              if((!confirmacion15)){//si la confirmacion no se hizo me fijo si ya pasaron 10 minutos para dejar el turno libre
              
                confirmacion15=true;//esto significa que todavia no confirmo pero esperamos 10 minutos para que confirme  

                var horaActual = moment().format('HH:mm:ss');
                var horaEnQueReservo = turno.horaEnQueReservo;
                var horaEnQueReservoObj = moment(horaEnQueReservo,'HH:mm:ss');
                var horaActualObj = moment(horaActual,'HH:mm:ss');
                var diferencia = horaActualObj.diff(horaEnQueReservoObj, 'minutes');
                
                if(diferencia > ESPERA){
                  confirmacion15=false
                }
              }
            }

            if(turno.dni==turnosDisponibles.data[0].dni16){      
              confirmacion16=turno.confirmacion;

              if((!confirmacion16)){//Si la confirmacion no se hizo me fijo si ya pasaron 10 minutos para dejar el turno libre
              
                confirmacion16=true;//esto significa que todavia no confirmo pero esperamos 10 minutos para que confirme  

                var horaActual = moment().format('HH:mm:ss');
                var horaEnQueReservo = turno.horaEnQueReservo;
                var horaEnQueReservoObj = moment(horaEnQueReservo,'HH:mm:ss');
                var horaActualObj = moment(horaActual,'HH:mm:ss');
                var diferencia = horaActualObj.diff(horaEnQueReservoObj, 'minutes');
                
                if(diferencia > ESPERA){
                  confirmacion16=false
                }
              }
            }

            if(turno.dni==turnosDisponibles.data[0].dni17){      
              confirmacion17=turno.confirmacion;

              if((!confirmacion17)){//Si la confirmacion no se hizo me fijo si ya pasaron 10 minutos para dejar el turno libre
              
                confirmacion17=true;//esto significa que todavia no confirmo pero esperamos 10 minutos para que confirme  

                var horaActual = moment().format('HH:mm:ss');
                var horaEnQueReservo = turno.horaEnQueReservo;
                var horaEnQueReservoObj = moment(horaEnQueReservo,'HH:mm:ss');
                var horaActualObj = moment(horaActual,'HH:mm:ss');
                var diferencia = horaActualObj.diff(horaEnQueReservoObj, 'minutes');
                
                if(diferencia > ESPERA){
                  confirmacion17=false
                }
              }
            }

            if(turno.dni==turnosDisponibles.data[0].dni18){      
              confirmacion18=turno.confirmacion;

              if((!confirmacion18)){//Si la confirmacion no se hizo me fijo si ya pasaron 10 minutos para dejar el turno libre
              
                confirmacion18=true;//esto significa que todavia no confirmo pero esperamos 10 minutos para que confirme  

                var horaActual = moment().format('HH:mm:ss');
                var horaEnQueReservo = turno.horaEnQueReservo;
                var horaEnQueReservoObj = moment(horaEnQueReservo,'HH:mm:ss');
                var horaActualObj = moment(horaActual,'HH:mm:ss');
                var diferencia = horaActualObj.diff(horaEnQueReservoObj, 'minutes');
                
                if(diferencia > ESPERA){
                  confirmacion18=false
                }
              }
            }

            if(turno.dni==turnosDisponibles.data[0].dni19){      
              confirmacion19=turno.confirmacion;

              if((!confirmacion19)){//Si la confirmacion no se hizo me fijo si ya pasaron 10 minutos para dejar el turno libre
              
                confirmacion19=true;//esto significa que todavia no confirmo pero esperamos 10 minutos para que confirme  

                var horaActual = moment().format('HH:mm:ss');
                var horaEnQueReservo = turno.horaEnQueReservo;
                var horaEnQueReservoObj = moment(horaEnQueReservo,'HH:mm:ss');
                var horaActualObj = moment(horaActual,'HH:mm:ss');
                var diferencia = horaActualObj.diff(horaEnQueReservoObj, 'minutes');
                
                if(diferencia > ESPERA){
                  confirmacion19=false
                }
              }
            }

          }       
        });
        //----------------------------------------------------------------------------------

        var turnosDisponiblesAux = turnosDisponibles.data[0];

        this.setState({
          turno1:(turnosDisponiblesAux.Turno1 && confirmacion1),
          turno2:(turnosDisponiblesAux.Turno2 && confirmacion2),
          turno3:(turnosDisponiblesAux.Turno3 && confirmacion3),
          turno4:(turnosDisponiblesAux.Turno4 && confirmacion4),
          turno5:(turnosDisponiblesAux.Turno5 && confirmacion5),

          turno6:(turnosDisponiblesAux.Turno6 && confirmacion6),
          turno7:(turnosDisponiblesAux.Turno7 && confirmacion7),
          turno8:(turnosDisponiblesAux.Turno8 && confirmacion8),
          turno9:(turnosDisponiblesAux.Turno9 && confirmacion9),
          turno10:(turnosDisponiblesAux.Turno10 && confirmacion10),

          turno11:(turnosDisponiblesAux.Turno11 && confirmacion11),
          turno12:(turnosDisponiblesAux.Turno12 && confirmacion12),
          turno13:(turnosDisponiblesAux.Turno13 && confirmacion13),
          turno14:(turnosDisponiblesAux.Turno14 && confirmacion14),
          turno15:(turnosDisponiblesAux.Turno15 && confirmacion15),

          turno16:(turnosDisponiblesAux.Turno16 && confirmacion16),
          turno17:(turnosDisponiblesAux.Turno17 && confirmacion17),
          turno18:(turnosDisponiblesAux.Turno18 && confirmacion18),
          turno19:(turnosDisponiblesAux.Turno19 && confirmacion19),

          dni1:turnosDisponiblesAux.dni1,
          dni2:turnosDisponiblesAux.dni2,
          dni3:turnosDisponiblesAux.dni3,
          dni4:turnosDisponiblesAux.dni4,
          dni5:turnosDisponiblesAux.dni5,

          dni6:turnosDisponiblesAux.dni6,
          dni7:turnosDisponiblesAux.dni7,
          dni8:turnosDisponiblesAux.dni8,
          dni9:turnosDisponiblesAux.dni9,
          dni10:turnosDisponiblesAux.dni10,

          dni11:turnosDisponiblesAux.dni11,
          dni12:turnosDisponiblesAux.dni12,
          dni13:turnosDisponiblesAux.dni13,
          dni14:turnosDisponiblesAux.dni14,
          dni15:turnosDisponiblesAux.dni15,

          dni16:turnosDisponiblesAux.dni16,
          dni17:turnosDisponiblesAux.dni17,
          dni18:turnosDisponiblesAux.dni18,
          dni19:turnosDisponiblesAux.dni19,

          nombreApellido1:turnosDisponiblesAux.nombreApellido1,
          nombreApellido2:turnosDisponiblesAux.nombreApellido2,
          nombreApellido3:turnosDisponiblesAux.nombreApellido3,
          nombreApellido4:turnosDisponiblesAux.nombreApellido4,
          nombreApellido5:turnosDisponiblesAux.nombreApellido5,

          nombreApellido6:turnosDisponiblesAux.nombreApellido6,
          nombreApellido7:turnosDisponiblesAux.nombreApellido7,
          nombreApellido8:turnosDisponiblesAux.nombreApellido8,
          nombreApellido9:turnosDisponiblesAux.nombreApellido9,
          nombreApellido10:turnosDisponiblesAux.nombreApellido10,

          nombreApellido11:turnosDisponiblesAux.nombreApellido11,
          nombreApellido12:turnosDisponiblesAux.nombreApellido12,
          nombreApellido13:turnosDisponiblesAux.nombreApellido13,
          nombreApellido14:turnosDisponiblesAux.nombreApellido14,
          nombreApellido15:turnosDisponiblesAux.nombreApellido15,

          nombreApellido16:turnosDisponiblesAux.nombreApellido16,
          nombreApellido17:turnosDisponiblesAux.nombreApellido17,
          nombreApellido18:turnosDisponiblesAux.nombreApellido18,
          nombreApellido19:turnosDisponiblesAux.nombreApellido19,

          mail1:turnosDisponiblesAux.mail1,
          mail2:turnosDisponiblesAux.mail2,
          mail3:turnosDisponiblesAux.mail3,
          mail4:turnosDisponiblesAux.mail4,
          mail5:turnosDisponiblesAux.mail5,

          mail6:turnosDisponiblesAux.mail6,
          mail7:turnosDisponiblesAux.mail7,
          mail8:turnosDisponiblesAux.mail8,
          mail9:turnosDisponiblesAux.mail9,
          mail10:turnosDisponiblesAux.mail10,

          mail11:turnosDisponiblesAux.mail11,
          mail12:turnosDisponiblesAux.mail12,
          mail13:turnosDisponiblesAux.mail13,
          mail14:turnosDisponiblesAux.mail14,
          mail15:turnosDisponiblesAux.mail15,

          mail16:turnosDisponiblesAux.mail16,
          mail17:turnosDisponiblesAux.mail17,
          mail18:turnosDisponiblesAux.mail18,
          mail19:turnosDisponiblesAux.mail19

        })
        
        this.setState({
          show:true,
        })
      }  
    }else{
      await axios.post(api.api+"/db/munirw/lotear/mesa2/turnosDisonible",{

          Turno1:this.state.turno1, 
          Turno2:this.state.turno2, 
          Turno3:this.state.turno3, 
          Turno4:this.state.turno4, 
          Turno5:this.state.turno5,    

          Turno6:this.state.turno6, 
          Turno7:this.state.turno7, 
          Turno8:this.state.turno8, 
          Turno9:this.state.turno9, 
          Turno10:this.state.turno10,    

          Turno11:this.state.turno11, 
          Turno12:this.state.turno12, 
          Turno13:this.state.turno13, 
          Turno14:this.state.turno14, 
          Turno15:this.state.turno15, 
          
          Turno16:this.state.turno16, 
          Turno17:this.state.turno17, 
          Turno18:this.state.turno18, 
          Turno19:this.state.turno19, 

          dni1:this.state.dni1,
          dni2:this.state.dni2,
          dni3:this.state.dni3,
          dni4:this.state.dni4,
          dni5:this.state.dni5,

          dni6:this.state.dni6,
          dni7:this.state.dni7,
          dni8:this.state.dni8,
          dni9:this.state.dni9,
          dni10:this.state.dni10,

          dni11:this.state.dni11,
          dni12:this.state.dni12,
          dni13:this.state.dni13,
          dni14:this.state.dni14,
          dni15:this.state.dni15,

          dni16:this.state.dni16,
          dni17:this.state.dni17,
          dni18:this.state.dni18,
          dni19:this.state.dni19,

          nombreApellido1:this.state.nombreApellido1,
          nombreApellido2:this.state.nombreApellido2,
          nombreApellido3:this.state.nombreApellido3,
          nombreApellido4:this.state.nombreApellido4,
          nombreApellido5:this.state.nombreApellido5,

          nombreApellido6:this.state.nombreApellido6,
          nombreApellido7:this.state.nombreApellido7,
          nombreApellido8:this.state.nombreApellido8,
          nombreApellido9:this.state.nombreApellido9,
          nombreApellido10:this.state.nombreApellido10,

          nombreApellido11:this.state.nombreApellido11,
          nombreApellido12:this.state.nombreApellido12,
          nombreApellido13:this.state.nombreApellido13,
          nombreApellido14:this.state.nombreApellido14,
          nombreApellido15:this.state.nombreApellido15,

          nombreApellido16:this.state.nombreApellido16,
          nombreApellido17:this.state.nombreApellido17,
          nombreApellido18:this.state.nombreApellido18,
          nombreApellido19:this.state.nombreApellido19,

          mail1:this.state.mail1,
          mail2:this.state.mail2,
          mail3:this.state.mail3,
          mail4:this.state.mail4,
          mail5:this.state.mail5,

          mail6:this.state.mail6,
          mail7:this.state.mail7,
          mail8:this.state.mail8,
          mail9:this.state.mail9,
          mail10:this.state.mail10,

          mail11:this.state.mail11,
          mail12:this.state.mail12,
          mail13:this.state.mail13,
          mail14:this.state.mail14,
          mail15:this.state.mail15,

          mail16:this.state.mail16,
          mail17:this.state.mail17,
          mail18:this.state.mail18,
          mail19:this.state.mail19,

          fechaTurno:this.state.dia,
      },
      {
        headers:{
          authorization: cookies.get('token')
        }
      }).then(response => {
        this.setState({
          show:true
        })
      }).catch(e => {
        console.log(e);
      })

    }
  }

  onChangeDia=async dia =>{

    console.log(dia);

    if( (String(dia).indexOf('Sat')>=0)||(String(dia).indexOf('Sun')>=0) ){
      alert("No se puede sacar turnos para Sabados o Domingos");
    }else if(String(dia).indexOf('Apr 02')>=0){
      alert("2 de abril: Día del Veterano y de los Caídos en la guerra de Malvinas");   
    }else{

    this.setState({  
      dia: String(moment(dia).format('LL')),
      loadHorarios:'esperar',
      preTurno: true,

      turno1:false,
      turno2:false,
      turno3:false,
      turno4:false,
      turno5:false,

      turno6:false,
      turno7:false,
      turno8:false,
      turno9:false,
      turno10:false,

      turno11:false,
      turno12:false,
      turno13:false,
      turno14:false,
      turno15:false,

      turno16:false,
      turno17:false,
      turno18:false,
      turno19:false,

      selTurno1:false,
      selTurno2:false,
      selTurno3:false,
      selTurno4:false,
      selTurno5:false,

      selTurno6:false,
      selTurno7:false,
      selTurno8:false,
      selTurno9:false,
      selTurno10:false,

      selTurno11:false,
      selTurno12:false,
      selTurno13:false,
      selTurno14:false,
      selTurno15:false,

      selTurno16:false,
      selTurno17:false,
      selTurno18:false,
      selTurno19:false,

      dni1:'',
      dni2:'',
      dni3:'',
      dni4:'',
      dni5:'',

      dni6:'',
      dni7:'',
      dni8:'',
      dni9:'',
      dni10:'',

      dni11:'',
      dni12:'',
      dni13:'',
      dni14:'',
      dni15:'',

      dni16:'',
      dni17:'',
      dni18:'',
      dni19:'',

      nombreApellido1:'',
      nombreApellido2:'',
      nombreApellido3:'',
      nombreApellido4:'',
      nombreApellido5:'',

      nombreApellido6:'',
      nombreApellido7:'',
      nombreApellido8:'',
      nombreApellido9:'',
      nombreApellido10:'',

      nombreApellido11:'',
      nombreApellido12:'',
      nombreApellido13:'',
      nombreApellido14:'',
      nombreApellido15:'',

      nombreApellido16:'',
      nombreApellido17:'',
      nombreApellido18:'',
      nombreApellido19:'',

      mail1:'',
      mail2:'',
      mail3:'',
      mail4:'',
      mail5:'',

      mail6:'',
      mail7:'',
      mail8:'',
      mail9:'',
      mail10:'',

      mail11:'',
      mail12:'',
      mail13:'',
      mail14:'',
      mail15:'',

      mail16:'',
      mail17:'',
      mail18:'',
      mail19:''

    })
    
    var diaFormat = String(moment(dia).format('LL'))
    var turnosDisponibles = await axios.get(api.api+"/db/munirw/lotear/mesa2/turnosDisonible/findForDate/"+diaFormat,{
      headers:{
        authorization: cookies.get('token'),
      }
    });

    this.setState({loadHorarios:'not'});

    if(turnosDisponibles.data.length>0){ 

        var turnosDisponiblesAux = turnosDisponibles.data[0];

        this.setState({
          turno1:turnosDisponiblesAux.Turno1,
          turno2:turnosDisponiblesAux.Turno2,
          turno3:turnosDisponiblesAux.Turno3,
          turno4:turnosDisponiblesAux.Turno4,
          turno5:turnosDisponiblesAux.Turno5,

          turno6:turnosDisponiblesAux.Turno6,
          turno7:turnosDisponiblesAux.Turno7,
          turno8:turnosDisponiblesAux.Turno8,
          turno9:turnosDisponiblesAux.Turno9,
          turno10:turnosDisponiblesAux.Turno10,

          turno11:turnosDisponiblesAux.Turno11,
          turno12:turnosDisponiblesAux.Turno12,
          turno13:turnosDisponiblesAux.Turno13,
          turno14:turnosDisponiblesAux.Turno14,
          turno15:turnosDisponiblesAux.Turno15,

          turno16:turnosDisponiblesAux.Turno16,
          turno17:turnosDisponiblesAux.Turno17,
          turno18:turnosDisponiblesAux.Turno18,
          turno19:turnosDisponiblesAux.Turno19,

          dni1:turnosDisponiblesAux.dni1,
          dni2:turnosDisponiblesAux.dni2,
          dni3:turnosDisponiblesAux.dni3,
          dni4:turnosDisponiblesAux.dni4,
          dni5:turnosDisponiblesAux.dni5,

          dni6:turnosDisponiblesAux.dni6,
          dni7:turnosDisponiblesAux.dni7,
          dni8:turnosDisponiblesAux.dni8,
          dni9:turnosDisponiblesAux.dni9,
          dni10:turnosDisponiblesAux.dni10,

          dni11:turnosDisponiblesAux.dni11,
          dni12:turnosDisponiblesAux.dni12,
          dni13:turnosDisponiblesAux.dni13,
          dni14:turnosDisponiblesAux.dni14,
          dni15:turnosDisponiblesAux.dni15,

          dni16:turnosDisponiblesAux.dni16,
          dni17:turnosDisponiblesAux.dni17,
          dni18:turnosDisponiblesAux.dni18,
          dni19:turnosDisponiblesAux.dni19,

          nombreApellido1:turnosDisponiblesAux.nombreApellido1,
          nombreApellido2:turnosDisponiblesAux.nombreApellido2,
          nombreApellido3:turnosDisponiblesAux.nombreApellido3,
          nombreApellido4:turnosDisponiblesAux.nombreApellido4,
          nombreApellido5:turnosDisponiblesAux.nombreApellido5,

          nombreApellido6:turnosDisponiblesAux.nombreApellido6,
          nombreApellido7:turnosDisponiblesAux.nombreApellido7,
          nombreApellido8:turnosDisponiblesAux.nombreApellido8,
          nombreApellido9:turnosDisponiblesAux.nombreApellido9,
          nombreApellido10:turnosDisponiblesAux.nombreApellido10,

          nombreApellido11:turnosDisponiblesAux.nombreApellido11,
          nombreApellido12:turnosDisponiblesAux.nombreApellido12,
          nombreApellido13:turnosDisponiblesAux.nombreApellido13,
          nombreApellido14:turnosDisponiblesAux.nombreApellido14,
          nombreApellido15:turnosDisponiblesAux.nombreApellido15,

          nombreApellido16:turnosDisponiblesAux.nombreApellido16,
          nombreApellido17:turnosDisponiblesAux.nombreApellido17,
          nombreApellido18:turnosDisponiblesAux.nombreApellido18,
          nombreApellido19:turnosDisponiblesAux.nombreApellido19,

          mail1:turnosDisponiblesAux.mail1,
          mail2:turnosDisponiblesAux.mail2,
          mail3:turnosDisponiblesAux.mail3,
          mail4:turnosDisponiblesAux.mail4,
          mail5:turnosDisponiblesAux.mail5,

          mail6:turnosDisponiblesAux.mail6,
          mail7:turnosDisponiblesAux.mail7,
          mail8:turnosDisponiblesAux.mail8,
          mail9:turnosDisponiblesAux.mail9,
          mail10:turnosDisponiblesAux.mail10,

          mail11:turnosDisponiblesAux.mail11,
          mail12:turnosDisponiblesAux.mail12,
          mail13:turnosDisponiblesAux.mail13,
          mail14:turnosDisponiblesAux.mail14,
          mail15:turnosDisponiblesAux.mail15,
          
          mail16:turnosDisponiblesAux.mail16,
          mail17:turnosDisponiblesAux.mail17,
          mail18:turnosDisponiblesAux.mail18,
          mail19:turnosDisponiblesAux.mail19,
          //show: true,
        })
      }
      this.getTurnosDisponible(String(moment(dia).format('LL')));
    }
  }
  
  handleDatosUsuarios(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  }
  
  handleClose=e=>{
    this.setState({show:false})
  }

  hora=event=>{
    if(event.target.value=="turno1"){
      this.setState({
        selTurno1:true,
        selTurno2:false,
        selTurno3:false,
        selTurno4:false,
        selTurno5:false,

        selTurno6:false,
        selTurno7:false,
        selTurno8:false,
        selTurno9:false,
        selTurno10:false,

        selTurno11:false,
        selTurno12:false,
        selTurno13:false,
        selTurno14:false,
        selTurno15:false,

        selTurno16:false,
        selTurno17:false,
        selTurno18:false,
        selTurno19:false,
      })
    }
    if(event.target.value=="turno2"){
      this.setState({
        selTurno1:false,
        selTurno2:true,
        selTurno3:false,
        selTurno4:false,
        selTurno5:false,

        selTurno6:false,
        selTurno7:false,
        selTurno8:false,
        selTurno9:false,
        selTurno10:false,

        selTurno11:false,
        selTurno12:false,
        selTurno13:false,
        selTurno14:false,
        selTurno15:false,

        selTurno16:false,
        selTurno17:false,
        selTurno18:false,
        selTurno19:false
      })
    }
    if(event.target.value=="turno3"){
      this.setState({
        selTurno1:false,
        selTurno2:false,
        selTurno3:true,
        selTurno4:false,
        selTurno5:false,

        selTurno6:false,
        selTurno7:false,
        selTurno8:false,
        selTurno9:false,
        selTurno10:false,

        selTurno11:false,
        selTurno12:false,
        selTurno13:false,
        selTurno14:false,
        selTurno15:false,

        selTurno16:false,
        selTurno17:false,
        selTurno18:false,
        selTurno19:false
      })
    }
    if(event.target.value=="turno4"){
      this.setState({
        selTurno1:false,
        selTurno2:false,
        selTurno3:false,
        selTurno4:true,
        selTurno5:false,

        selTurno6:false,
        selTurno7:false,
        selTurno8:false,
        selTurno9:false,
        selTurno10:false,

        selTurno11:false,
        selTurno12:false,
        selTurno13:false,
        selTurno14:false,
        selTurno15:false,

        selTurno16:false,
        selTurno17:false,
        selTurno18:false,
        selTurno19:false
      })
    }
    if(event.target.value=="turno5"){
      this.setState({
        selTurno1:false,
        selTurno2:false,
        selTurno3:false,
        selTurno4:false,
        selTurno5:true,

        selTurno6:false,
        selTurno7:false,
        selTurno8:false,
        selTurno9:false,
        selTurno10:false,

        selTurno11:false,
        selTurno12:false,
        selTurno13:false,
        selTurno14:false,
        selTurno15:false,

        selTurno16:false,
        selTurno17:false,
        selTurno18:false,
        selTurno19:false
      })
    }
    //------------------------
    if(event.target.value=="turno6"){
      this.setState({
        selTurno1:false,
        selTurno2:false,
        selTurno3:false,
        selTurno4:false,
        selTurno5:false,

        selTurno6:true,
        selTurno7:false,
        selTurno8:false,
        selTurno9:false,
        selTurno10:false,

        selTurno11:false,
        selTurno12:false,
        selTurno13:false,
        selTurno14:false,
        selTurno15:false,

        selTurno16:false,
        selTurno17:false,
        selTurno18:false,
        selTurno19:false
      })
    }
    if(event.target.value=="turno7"){
      this.setState({
        selTurno1:false,
        selTurno2:false,
        selTurno3:false,
        selTurno4:false,
        selTurno5:false,

        selTurno6:false,
        selTurno7:true,
        selTurno8:false,
        selTurno9:false,
        selTurno10:false,

        selTurno11:false,
        selTurno12:false,
        selTurno13:false,
        selTurno14:false,
        selTurno15:false,

        selTurno16:false,
        selTurno17:false,
        selTurno18:false,
        selTurno19:false
        
      })
    }
    if(event.target.value=="turno8"){
      this.setState({
        selTurno1:false,
        selTurno2:false,
        selTurno3:false,
        selTurno4:false,
        selTurno5:false,

        selTurno6:false,
        selTurno7:false,
        selTurno8:true,
        selTurno9:false,
        selTurno10:false,

        selTurno11:false,
        selTurno12:false,
        selTurno13:false,
        selTurno14:false,
        selTurno15:false,

        selTurno16:false,
        selTurno17:false,
        selTurno18:false,
        selTurno19:false
      })
    }
    if(event.target.value=="turno9"){
      this.setState({
        selTurno1:false,
        selTurno2:false,
        selTurno3:false,
        selTurno4:false,
        selTurno5:false,

        selTurno6:false,
        selTurno7:false,
        selTurno8:false,
        selTurno9:true,
        selTurno10:false,

        selTurno11:false,
        selTurno12:false,
        selTurno13:false,
        selTurno14:false,
        selTurno15:false,

        selTurno16:false,
        selTurno17:false,
        selTurno18:false,
        selTurno19:false
      })
    }
    if(event.target.value=="turno10"){
      this.setState({
        selTurno1:false,
        selTurno2:false,
        selTurno3:false,
        selTurno4:false,
        selTurno5:false,

        selTurno6:false,
        selTurno7:false,
        selTurno8:false,
        selTurno9:false,
        selTurno10:true,

        selTurno11:false,
        selTurno12:false,
        selTurno13:false,
        selTurno14:false,
        selTurno15:false,

        selTurno16:false,
        selTurno17:false,
        selTurno18:false,
        selTurno19:false
      })
    }
    //--------------------------------
    if(event.target.value=="turno11"){
      this.setState({
        selTurno1:false,
        selTurno2:false,
        selTurno3:false,
        selTurno4:false,
        selTurno5:false,

        selTurno6:false,
        selTurno7:false,
        selTurno8:false,
        selTurno9:false,
        selTurno10:false,

        selTurno11:true,
        selTurno12:false,
        selTurno13:false,
        selTurno14:false,
        selTurno15:false,

        selTurno16:false,
        selTurno17:false,
        selTurno18:false,
        selTurno19:false
      })
    }
    if(event.target.value=="turno12"){
      this.setState({
        selTurno1:false,
        selTurno2:false,
        selTurno3:false,
        selTurno4:false,
        selTurno5:false,

        selTurno6:false,
        selTurno7:false,
        selTurno8:false,
        selTurno9:false,
        selTurno10:false,

        selTurno11:false,
        selTurno12:true,
        selTurno13:false,
        selTurno14:false,
        selTurno15:false,

        selTurno16:false,
        selTurno17:false,
        selTurno18:false,
        selTurno19:false
      })
    }
    if(event.target.value=="turno13"){
      this.setState({
        selTurno1:false,
        selTurno2:false,
        selTurno3:false,
        selTurno4:false,
        selTurno5:false,

        selTurno6:false,
        selTurno7:false,
        selTurno8:false,
        selTurno9:false,
        selTurno10:false,

        selTurno11:false,
        selTurno12:false,
        selTurno13:true,
        selTurno14:false,
        selTurno15:false,

        selTurno16:false,
        selTurno17:false,
        selTurno18:false,
        selTurno19:false
      })
    }
    if(event.target.value=="turno14"){
      this.setState({
        selTurno1:false,
        selTurno2:false,
        selTurno3:false,
        selTurno4:false,
        selTurno5:false,

        selTurno6:false,
        selTurno7:false,
        selTurno8:false,
        selTurno9:false,
        selTurno10:false,

        selTurno11:false,
        selTurno12:false,
        selTurno13:false,
        selTurno14:true,
        selTurno15:false,

        selTurno16:false,
        selTurno17:false,
        selTurno18:false,
        selTurno19:false
      })
    }
    if(event.target.value=="turno15"){
      this.setState({
        selTurno1:false,
        selTurno2:false,
        selTurno3:false,
        selTurno4:false,
        selTurno5:false,

        selTurno6:false,
        selTurno7:false,
        selTurno8:false,
        selTurno9:false,
        selTurno10:false,

        selTurno11:false,
        selTurno12:false,
        selTurno13:false,
        selTurno14:false,
        selTurno15:true,

        selTurno16:false,
        selTurno17:false,
        selTurno18:false,
        selTurno19:false
      })
    }
    if(event.target.value=="turno16"){
      this.setState({
        selTurno1:false,
        selTurno2:false,
        selTurno3:false,
        selTurno4:false,
        selTurno5:false,

        selTurno6:false,
        selTurno7:false,
        selTurno8:false,
        selTurno9:false,
        selTurno10:false,

        selTurno11:false,
        selTurno12:false,
        selTurno13:false,
        selTurno14:false,
        selTurno15:false,

        selTurno16:true,
        selTurno17:false,
        selTurno18:false,
        selTurno19:false
      })
    }
    if(event.target.value=="turno17"){
      this.setState({
        selTurno1:false,
        selTurno2:false,
        selTurno3:false,
        selTurno4:false,
        selTurno5:false,

        selTurno6:false,
        selTurno7:false,
        selTurno8:false,
        selTurno9:false,
        selTurno10:false,

        selTurno11:false,
        selTurno12:false,
        selTurno13:false,
        selTurno14:false,
        selTurno15:false,

        selTurno16:false,
        selTurno17:true,
        selTurno18:false,
        selTurno19:false
      })
    }
    if(event.target.value=="turno18"){
      this.setState({
        selTurno1:false,
        selTurno2:false,
        selTurno3:false,
        selTurno4:false,
        selTurno5:false,

        selTurno6:false,
        selTurno7:false,
        selTurno8:false,
        selTurno9:false,
        selTurno10:false,

        selTurno11:false,
        selTurno12:false,
        selTurno13:false,
        selTurno14:false,
        selTurno15:false,

        selTurno16:false,
        selTurno17:false,
        selTurno18:true,
        selTurno19:false
      })
    }
    if(event.target.value=="turno19"){
      this.setState({
        selTurno1:false,
        selTurno2:false,
        selTurno3:false,
        selTurno4:false,
        selTurno5:false,

        selTurno6:false,
        selTurno7:false,
        selTurno8:false,
        selTurno9:false,
        selTurno10:false,

        selTurno11:false,
        selTurno12:false,
        selTurno13:false,
        selTurno14:false,
        selTurno15:false,

        selTurno16:false,
        selTurno17:false,
        selTurno18:false,
        selTurno19:true
      })
    }
  }

  reservar=async e=>{

    if( 
        !this.state.selTurno1 && !this.state.selTurno2 && !this.state.selTurno3 && !this.state.selTurno4 && !this.state.selTurno5 &&
        !this.state.selTurno6 && !this.state.selTurno7 && !this.state.selTurno8 && !this.state.selTurno9 && !this.state.selTurno10 &&
        !this.state.selTurno11 && !this.state.selTurno12 && !this.state.selTurno13 && !this.state.selTurno14 && !this.state.selTurno15 &&
        !this.state.selTurno16 && !this.state.selTurno17 && !this.state.selTurno18 && !this.state.selTurno19
    ){

      alert("Se debe elegir una fecha y hora para el Turno)");  

    }else if((this.state.nya=='')||(this.state.dni=='')||(this.state.email=='')){
      alert("Primero completá tus datos (Nombre, Apellido, dni y email)");
    }else{
      //---controlar que la persona no saque dos turnos el mismo dia----
      var turnosDisponibles = await axios.get(api.api+"/db/munirw/lotear/mesa2/turnosDisonible/findForDate/"+this.state.dia,{
        headers:{
          authorization: cookies.get('token'),
        }
      });
      var turnosDisponiblesAux = turnosDisponibles.data[0];
      //================================================================================
      //Si no alcanzo a confirmar el turno, debo dejar que haga mas intentos en el dia para sacar turno a pesar de que se
      //trate del mismo dni o mail. 
      var dniConConfirmacion = false;
      var mailConConfirmacion = false;

      var ChekSidniYaUsadoHoy = await axios.get(api.api+"/db/munirw/lotear/mesa2/turno/porDni/"+this.state.dni+"/"+this.state.dia,{
        headers:{
          authorization: cookies.get('token'),
        }
      });

      var ChekSimailYaUsadoHoy = await axios.get(api.api+"/db/munirw/lotear/mesa2/turno/porMail/"+this.state.email+"/"+this.state.dia,{
        headers:{
          authorization: cookies.get('token'),
        }
      });

     
      //si por dni esta confirmado no debo dejar que reserve con este dni nuevamente
      if(ChekSidniYaUsadoHoy.data.confirmacion!=undefined){
        dniConConfirmacion=ChekSidniYaUsadoHoy.data.confirmacion
      }
      if(ChekSimailYaUsadoHoy.data.confirmacion!=undefined){
        mailConConfirmacion=ChekSimailYaUsadoHoy.data.confirmacion
      }
      

      var presenciasDni = 1; //para controlar si un usuario quiere sacar mas de un turno en un dia
      var presenciasEmail = 1;

      for (const property in turnosDisponiblesAux) {
        if(turnosDisponiblesAux[property]==this.state.dni){
          presenciasDni++;
        }      
        if(turnosDisponiblesAux[property]==this.state.email){
          presenciasEmail++;
        }      
      }

      if( ((presenciasDni>1)||(presenciasEmail>1))&&(dniConConfirmacion||mailConConfirmacion) ){  
        alert("Ya hay un turno registrado para el dia de hoy con el dni o email ingresado, no puede solicitar dos turnos el mismo dia.");   
      }else{
      //----------------------------------------------------------------

        //if(!this.state.selTurno1 && !this.state.selTurno2 && !this.state.selTurno3 && !this.state.selTurno4 && !this.state.selTurno5){
        //    alert("Se debe elegir una fecha y hora para el Turno)");  
        //}else if((this.state.nya=='')||(this.state.dni=='')||(this.state.email=='')){
        //    alert("Primero completá tus datos (Nombre, Apellido, dni y email)");
        //}else{

          if(this.state.selTurno1){
            this.setState({
              turno1:true,
              dni1:this.state.dni,
              nombreApellido1:this.state.nya,
              mail1:this.state.email,
              esperaEnvio:true
            });
          }
          if(this.state.selTurno2){
            this.setState({
              turno2:true,
              dni2:this.state.dni,
              nombreApellido2:this.state.nya,
              mail2:this.state.email,
              esperaEnvio:true
            });
          }
          if(this.state.selTurno3){
            this.setState({
              turno3:true,
              dni3:this.state.dni,
              nombreApellido3:this.state.nya,
              mail3:this.state.email,
              esperaEnvio:true
            });
          }
          if(this.state.selTurno4){ 
            this.setState({
              turno4:true,
              dni4:this.state.dni,
              nombreApellido4:this.state.nya,
              mail4:this.state.email,
              esperaEnvio:true
            });
          }
          if(this.state.selTurno5){
            this.setState({
              turno5:true,
              dni5:this.state.dni,
              nombreApellido5:this.state.nya,
              mail5:this.state.email,
              esperaEnvio:true
            });
          }
          
          //---------------------------------------

          if(this.state.selTurno6){
            this.setState({
              turno6:true,
              dni6:this.state.dni,
              nombreApellido6:this.state.nya,
              mail6:this.state.email,
              esperaEnvio:true
            });
          }
          if(this.state.selTurno7){
            this.setState({
              turno7:true,
              dni7:this.state.dni,
              nombreApellido7:this.state.nya,
              mail7:this.state.email,
              esperaEnvio:true
            });
          }
          if(this.state.selTurno8){
            this.setState({
              turno8:true,
              dni8:this.state.dni,
              nombreApellido8:this.state.nya,
              mail8:this.state.email,
              esperaEnvio:true
            });
          }
          if(this.state.selTurno9){ 
            this.setState({
              turno9:true,
              dni9:this.state.dni,
              nombreApellido9:this.state.nya,
              mail9:this.state.email,
              esperaEnvio:true
            });
          }
          if(this.state.selTurno10){
            this.setState({
              turno10:true,
              dni10:this.state.dni,
              nombreApellido10:this.state.nya,
              mail10:this.state.email,
              esperaEnvio:true
            });
          }

          //-------------------------------------------------

          if(this.state.selTurno11){
            this.setState({
              turno11:true,
              dni11:this.state.dni,
              nombreApellido11:this.state.nya,
              mail11:this.state.email,
              esperaEnvio:true
            });
          }
          if(this.state.selTurno12){
            this.setState({
              turno12:true,
              dni12:this.state.dni,
              nombreApellido12:this.state.nya,
              mail12:this.state.email,
              esperaEnvio:true
            });
          }
          if(this.state.selTurno13){
            this.setState({
              turno13:true,
              dni13:this.state.dni,
              nombreApellido13:this.state.nya,
              mail13:this.state.email,
              esperaEnvio:true
            });
          }
          if(this.state.selTurno14){ 
            this.setState({
              turno14:true,
              dni14:this.state.dni,
              nombreApellido14:this.state.nya,
              mail14:this.state.email,
              esperaEnvio:true
            });
          }
          if(this.state.selTurno15){
            this.setState({
              turno15:true,
              dni15:this.state.dni,
              nombreApellido15:this.state.nya,
              mail15:this.state.email,
              esperaEnvio:true
            });
          }

          if(this.state.selTurno16){
            this.setState({
              turno16:true,
              dni16:this.state.dni,
              nombreApellido16:this.state.nya,
              mail16:this.state.email,
              esperaEnvio:true
            });
          }
          if(this.state.selTurno17){
            this.setState({
              turno17:true,
              dni17:this.state.dni,
              nombreApellido17:this.state.nya,
              mail17:this.state.email,
              esperaEnvio:true
            });
          }
          if(this.state.selTurno18){
            this.setState({
              turno18:true,
              dni18:this.state.dni,
              nombreApellido18:this.state.nya,
              mail18:this.state.email,
              esperaEnvio:true
            });
          }
          if(this.state.selTurno19){
            this.setState({
              turno19:true,
              dni19:this.state.dni,
              nombreApellido19:this.state.nya,
              mail19:this.state.email,
              esperaEnvio:true
            });
          }


          await axios.post(api.api+"/db/munirw/lotear/mesa2/turno",{
              fechaTurno:this.state.dia,
              dni:this.state.dni,
              email:this.state.email,
              nombreApellido:this.state.nya,
              preTurno:this.state.preTurno,
              confirmacion:this.state.confirmacion,
              horaEnQueReservo:moment().format('HH:mm:ss'),
          },
          {
            headers:{
              authorization: cookies.get('token')
            }
          })

          var dia=this.state.dia;
          await axios.put(api.api+"/db/munirw/lotear/mesa2/turnosDisonible/findForDate/"+dia,{
              Turno1:this.state.turno1, 
              Turno2:this.state.turno2, 
              Turno3:this.state.turno3, 
              Turno4:this.state.turno4, 
              Turno5:this.state.turno5,    

              Turno6:this.state.turno6, 
              Turno7:this.state.turno7, 
              Turno8:this.state.turno8, 
              Turno9:this.state.turno9, 
              Turno10:this.state.turno10,    

              Turno11:this.state.turno11, 
              Turno12:this.state.turno12, 
              Turno13:this.state.turno13, 
              Turno14:this.state.turno14, 
              Turno15:this.state.turno15,    

              Turno16:this.state.turno16, 
              Turno17:this.state.turno17, 
              Turno18:this.state.turno18, 
              Turno19:this.state.turno19, 

              dni1:this.state.dni1,
              dni2:this.state.dni2,
              dni3:this.state.dni3,
              dni4:this.state.dni4,
              dni5:this.state.dni5,

              dni6:this.state.dni6,
              dni7:this.state.dni7,
              dni8:this.state.dni8,
              dni9:this.state.dni9,
              dni10:this.state.dni10,

              dni11:this.state.dni11,
              dni12:this.state.dni12,
              dni13:this.state.dni13,
              dni14:this.state.dni14,
              dni15:this.state.dni15,

              dni16:this.state.dni16,
              dni17:this.state.dni17,
              dni18:this.state.dni18,
              dni19:this.state.dni19,

              nombreApellido1:this.state.nombreApellido1,
              nombreApellido2:this.state.nombreApellido2,
              nombreApellido3:this.state.nombreApellido3,
              nombreApellido4:this.state.nombreApellido4,
              nombreApellido5:this.state.nombreApellido5,

              nombreApellido6:this.state.nombreApellido6,
              nombreApellido7:this.state.nombreApellido7,
              nombreApellido8:this.state.nombreApellido8,
              nombreApellido9:this.state.nombreApellido9,
              nombreApellido10:this.state.nombreApellido10,

              nombreApellido11:this.state.nombreApellido11,
              nombreApellido12:this.state.nombreApellido12,
              nombreApellido13:this.state.nombreApellido13,
              nombreApellido14:this.state.nombreApellido14,
              nombreApellido15:this.state.nombreApellido15,

              nombreApellido16:this.state.nombreApellido16,
              nombreApellido17:this.state.nombreApellido17,
              nombreApellido18:this.state.nombreApellido18,
              nombreApellido19:this.state.nombreApellido19,
              
              mail1:this.state.mail1,
              mail2:this.state.mail2,
              mail3:this.state.mail3,
              mail4:this.state.mail4,
              mail5:this.state.mail5,

              mail6:this.state.mail6,
              mail7:this.state.mail7,
              mail8:this.state.mail8,
              mail9:this.state.mail9,
              mail10:this.state.mail10,

              mail11:this.state.mail11,
              mail12:this.state.mail12,
              mail13:this.state.mail13,
              mail14:this.state.mail14,
              mail15:this.state.mail15,

              mail16:this.state.mail16,
              mail17:this.state.mail17,
              mail18:this.state.mail18,
              mail19:this.state.mail19,
              
              fechaTurno:this.state.dia
          },
          {
            headers:{
              authorization: cookies.get('token')
            }
          })
          
          await axios.post(api.api+"/db/munirw/lotear/mesa2/msj",{
              fechaTurno:this.state.dia,
              dni:this.state.dni,
              email:this.state.email,
              nombreApellido:this.state.nya,
          },
          {
            headers:{
              authorization: cookies.get('token')
            }
          }).then(response => {
            this.setState({
              dia:String(moment().format('LL')),
              esperaEnvio:false,
              nya:'',
              dni:0,
              email:'',
              preTurno:false,
              confirmacion:false,
              ctrlTurnosCreados:false,
              show:false,

              turno1:false,
              turno2:false,
              turno3:false,
              turno4:false,
              turno5:false,

              turno6:false,
              turno7:false,
              turno8:false,
              turno9:false,
              turno10:false,

              turno11:false,
              turno12:false,
              turno13:false,
              turno14:false,
              turno15:false,

              turno16:false,
              turno17:false,
              turno18:false,
              turno19:false,

              selTurno1:false,
              selTurno2:false,
              selTurno3:false,
              selTurno4:false,
              selTurno5:false,

              selTurno6:false,
              selTurno7:false,
              selTurno8:false,
              selTurno9:false,
              selTurno10:false,

              selTurno11:false,
              selTurno12:false,
              selTurno13:false,
              selTurno14:false,
              selTurno15:false,

              selTurno16:false,
              selTurno17:false,
              selTurno18:false,
              selTurno19:false,

              dni1:'',
              dni2:'',
              dni3:'',
              dni4:'',
              dni5:'',

              dni6:'',
              dni7:'',
              dni8:'',
              dni9:'',
              dni10:'',

              dni11:'',
              dni12:'',
              dni13:'',
              dni14:'',
              dni15:'',

              dni16:'',
              dni17:'',
              dni18:'',
              dni19:'',

              nombreApellido1:'',
              nombreApellido2:'',
              nombreApellido3:'',
              nombreApellido4:'',
              nombreApellido5:'',

              nombreApellido6:'',
              nombreApellido7:'',
              nombreApellido8:'',
              nombreApellido9:'',
              nombreApellido10:'',

              nombreApellido11:'',
              nombreApellido12:'',
              nombreApellido13:'',
              nombreApellido14:'',
              nombreApellido15:'',

              nombreApellido16:'',
              nombreApellido17:'',
              nombreApellido18:'',
              nombreApellido19:'',

              mail1:'',
              mail2:'',
              mail3:'',
              mail4:'',
              mail5:'',

              mail6:'',
              mail7:'',
              mail8:'',
              mail9:'',
              mail10:'',

              mail11:'',
              mail12:'',
              mail13:'',
              mail14:'',
              mail15:'',

              mail16:'',
              mail17:'',
              mail18:'',
              mail19:'',
            })
            alert("Confirmá tu turno entrando al mail que usaste tenés 10 minutos para hacerlo");
          }).catch(e => {
            console.log(e);
          })     
        //--}
      }
    }  
  }

  render() {
    if(this.state.esperaEnvio){
      return (
        <React.Fragment>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-4"></div>
              <div className="col-md-4">
                <Card>
                    <Card.Img variant="top" src={require('../images/turnos/Loading.gif')} />
                    <Card.Body>
                      <Card.Title>Enviando Confirmación a su Mail</Card.Title>
                      <Card.Text>

                      </Card.Text>
                    </Card.Body>
                </Card>  
              </div>
              <div className="col-md-4"></div>
            </div> 
          </div>
        </React.Fragment> 
      )
    }else{
      return (

        <React.Fragment> 

          <div className="container-fluid pt-5 pb-5">
            <div className="row">
              <div className="col-md-12" align="center"><h3>TURNOS LOTEAR MESA 2</h3></div>
            </div>
            <div className="row">
              <div className="col-md-12"><h3>-</h3></div>
            </div>
      
            <div className="row">
              <div className="col-md-1"></div>
              <div className="col-md-4">

              <Card>
                <Card.Img variant="top" src={require('../images/turnos/banner.jpg')} />
                <Card.Body>
                  <Card.Title>Ingresá tus datos para solicitar turno</Card.Title>
                  <Card.Text>

                  <Form>

                  <Form.Group controlId="nya">
                    <Form.Label>Nombre y Apellido</Form.Label>
                    <Form.Control autocomplete="off" type="text" name="nya" placeholder="Nombre y Apellido" onChange={this.handleDatosUsuarios} value={this.state.nya}/>
                  </Form.Group>

                  <Form.Group controlId="dni">
                    <Form.Label>DNI</Form.Label>
                    <Form.Control autocomplete="off" type="text" name="dni" placeholder="dni" onChange={this.handleDatosUsuarios} value={this.state.dni}/>
                  </Form.Group>

                  <Form.Group controlId="email">
                    <Form.Label>Email (se recomienda gmail)</Form.Label>
                    <Form.Control autocomplete="off" type="email" name="email" placeholder="Email" onChange={this.handleDatosUsuarios} value={this.state.email}/>
                  </Form.Group>

                  </Form>
                    
                  </Card.Text>
                </Card.Body>
              </Card>  
              
              
        
              <div className="col-md-1"></div>

              </div>

              <div className="col-md-1"></div>

              <div className="col-md-4">
              
              <Card>
                <Card.Img variant="top" src={require('../images/turnos/logoLotear.png')} />
                <Card.Body>
                  <Card.Title>Mesa 2 elegí el dia y hora disponibles</Card.Title>
                  {
                    this.state.loadHorarios=='esperar'
                    ?
                    <div className="col-md-12" align="center">
                      <h5>Obteniendo Horarios</h5>
                      <img src={require('../images/turnos/LoadingHorarios.gif')} width="80" height="80" />
                    </div>
                    
                    :
                    <div className="col-md-12" align="center"><h3></h3></div>
                  }  
                  <Card.Text>
                    <Calendar  
                      onChange={this.onChangeDia}
                      value={this.state.date}
                      minDate={this.state.diaInicial}
                      maxDate={new Date(2022,8,31)} 
                    />  

                    <Form.Group className="pt-5" controlId="guardar">
                      <Button  variant="primary" type="submit" onClick={this.reservar}>
                        RESERVAR TURNO
                      </Button>

                      <Link to="/lotear/mesa1">
                        <Button  variant="primary" style={{marginLeft:10}}>
                          IR A MESA 1
                        </Button>
                      </Link>
                    </Form.Group>

                  </Card.Text>
                </Card.Body>
              </Card>  
              </div>

              <div className="col-md-1"></div>
            </div>

          </div>

          <div className="container-fluid tonina">
            <div className="col-lg-12"> 
              <div className="row rowAltura">

              <Modal show={this.state.show} onHide={this.handleClose}>
                
                <Modal.Header closeButton>
                  <Modal.Title>Elegí una Hora Disponible en la Mesa de atención 2</Modal.Title>
                </Modal.Header>
                
                <Modal.Body>

                  <div className="row">

                    <div className="col-md-12">
                      <Form.Group controlId="formBasicCheckbox">
                        {/*<h5>Mesa 2</h5>*/}
                        
                        <div className="row">

                        <div className="col-md-6">

                        <div class="form-check">
                          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value="turno1" disabled={this.state.turno1} onClick={this.hora}/> {/*checked checked={this.state.chek}*/}
                          <h5 class="form-check-label" for="flexRadioDefault1">
                            10:00
                          </h5>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value="turno2" disabled={this.state.turno2} onClick={this.hora}/>
                          <h5 class="form-check-label" for="flexRadioDefault2">
                            10:20
                          </h5>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" value="turno3" disabled={this.state.turno3} onClick={this.hora}/>
                          <h5 class="form-check-label" for="flexRadioDefault1">
                            10:40
                          </h5>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault4" value="turno4" disabled={this.state.turno4} onClick={this.hora}/>
                          <h5 class="form-check-label" for="flexRadioDefault2">
                            11:00
                          </h5>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault5" value="turno5" disabled={this.state.turno5} onClick={this.hora}/>
                          <h5 class="form-check-label" for="flexRadioDefault2">
                            11:20
                          </h5>
                        </div>
              
                        <div class="form-check">
                          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault6" value="turno6" disabled={this.state.turno6} onClick={this.hora}/>
                          <h5 class="form-check-label" for="flexRadioDefault2">
                            11:40
                          </h5>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault7" value="turno7" disabled={this.state.turno7} onClick={this.hora}/>
                          <h5 class="form-check-label" for="flexRadioDefault2">
                            12:00
                          </h5>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault8" value="turno8" disabled={this.state.turno8} onClick={this.hora}/>
                          <h5 class="form-check-label" for="flexRadioDefault2">
                            12:20
                          </h5>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault9" value="turno9" disabled={this.state.turno9} onClick={this.hora}/>
                          <h5 class="form-check-label" for="flexRadioDefault2">
                            12:40
                          </h5>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault10" value="turno10" disabled={this.state.turno10} onClick={this.hora}/>
                          <h5 class="form-check-label" for="flexRadioDefault2">
                            13:00
                          </h5>
                        </div>

                        </div>
                        <div className="col-md-6">

                        <div class="form-check">
                          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault11" value="turno11" disabled={this.state.turno11} onClick={this.hora}/>
                          <h5 class="form-check-label" for="flexRadioDefault2">
                            13:20
                          </h5>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault12" value="turno12" disabled={this.state.turno12} onClick={this.hora}/>
                          <h5 class="form-check-label" for="flexRadioDefault2">
                            13:40
                          </h5>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault13" value="turno13" disabled={this.state.turno13} onClick={this.hora}/>
                          <h5 class="form-check-label" for="flexRadioDefault2">
                            14:00
                          </h5>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault14" value="turno14" disabled={this.state.turno14} onClick={this.hora}/>
                          <h5 class="form-check-label" for="flexRadioDefault2">
                            14:20
                          </h5>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault15" value="turno15" disabled={this.state.turno15} onClick={this.hora}/>
                          <h5 class="form-check-label" for="flexRadioDefault2">
                            14:40
                          </h5>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault16" value="turno16" disabled={this.state.turno16} onClick={this.hora}/>
                          <h5 class="form-check-label" for="flexRadioDefault2">
                            15:00
                          </h5>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault17" value="turno17" disabled={this.state.turno17} onClick={this.hora}/>
                          <h5 class="form-check-label" for="flexRadioDefault2">
                            15:20
                          </h5>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault18" value="turno18" disabled={this.state.turno18} onClick={this.hora}/>
                          <h5 class="form-check-label" for="flexRadioDefault2">
                            15:40
                          </h5>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault19" value="turno19" disabled={this.state.turno19} onClick={this.hora}/>
                          <h5 class="form-check-label" for="flexRadioDefault2">
                            16:00
                          </h5>
                        </div>
                        </div>
                        </div>
                      </Form.Group>
                    </div>


                  </div>  

                </Modal.Body>
                
                <Modal.Footer>
                  <Link to="/lotear/mesa1">
                    <Button variant="primary">
                      VER TURNOS DISPONIBLES DE LA MESA 1
                    </Button>
                  </Link>
                  <Button variant="primary" onClick={this.handleClose}>
                    ACEPTAR
                  </Button>
                  </Modal.Footer> 
                </Modal>

              </div>  
            </div>
          </div>
        </React.Fragment>
      
      );
    }
  }
}

export default Badges;
